import React, { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import './allquestion.scss';
import { GetWord } from "../../../../utils";
import { Table } from "antd";

const Allquestion = () => {

    const [pageName, setPageName] = useOutletContext();

    setPageName(GetWord("Tüm Sorular"));

    const ListColumns = [
        {
            title:
                <div className="AllQuestion-LessonName">Ders Adı</div>,
            dataIndex: 'lessonName',

        },
        {
            title: 'Toplam Soru Sayısı',
            dataIndex: 'totalQuestions',
        },
        {
            title: 'Cevaplanmayan Soru Sayısı',
            dataIndex: 'unansweredQuestions',
        },
        {
            title: 'Eğitmen Sayısı',
            dataIndex: 'numberOfInstructors',
        },
        {
            title: 'İşlemler',
            dataIndex: 'button',
        },
    ];

    const ListData = [
        {
            key: '1',
            lessonName: <div className="AllQuestion-LessonName">Türkçe</div>,
            totalQuestions: '112.598',
            unansweredQuestions: '128',
            numberOfInstructors: '754',
            button: <button className="AllQuestion-QuestionButton" onClick={<Allquestion />} style={{ cursor: 'pointer' }}><span className="AllQuestion-QuestionButton-Text">Sorular</span></button>,
        },
        {
            key: '2',
            lessonName: <div className="AllQuestion-LessonName">Matematik</div>,
            totalQuestions: '112.598',
            unansweredQuestions: '128',
            numberOfInstructors: '754',
            button: <button className="AllQuestion-QuestionButton" onClick={<Allquestion />} style={{ cursor: 'pointer' }}><span className="AllQuestion-QuestionButton-Text">Sorular</span></button>,
        },
        {
            key: '3',
            lessonName: <div className="AllQuestion-LessonName">Fen Bilgisi</div>,
            totalQuestions: '112.598',
            unansweredQuestions: '128',
            numberOfInstructors: '754',
            button: <button className="AllQuestion-QuestionButton" onClick={<Allquestion />} style={{ cursor: 'pointer' }}><span className="AllQuestion-QuestionButton-Text">Sorular</span></button>,
        },
        {
            key: '4',
            lessonName: <div className="AllQuestion-LessonName">Sosyal Bilgiler</div>,
            totalQuestions: '112.598',
            unansweredQuestions: '128',
            numberOfInstructors: '754',
            button: <button className="AllQuestion-QuestionButton" onClick={<Allquestion />} style={{ cursor: 'pointer' }}><span className="AllQuestion-QuestionButton-Text">Sorular</span></button>,
        },
        {
            key: '5',
            lessonName: <div className="AllQuestion-LessonName">Fizik</div>,
            totalQuestions: '112.598',
            unansweredQuestions: '128',
            numberOfInstructors: '754',
            button: <button className="AllQuestion-QuestionButton" onClick={<Allquestion />} style={{ cursor: 'pointer' }}><span className="AllQuestion-QuestionButton-Text">Sorular</span></button>,
        },
        {
            key: '6',
            lessonName: <div className="AllQuestion-LessonName">Kimya</div>,
            totalQuestions: '112.598',
            unansweredQuestions: '128',
            numberOfInstructors: '754',
            button: <button className="AllQuestion-QuestionButton" onClick={<Allquestion />} style={{ cursor: 'pointer' }}><span className="AllQuestion-QuestionButton-Text">Sorular</span></button>,
        },
        {
            key: '7',
            lessonName: <div className="AllQuestion-LessonName">Biyoloji</div>,
            totalQuestions: '112.598',
            unansweredQuestions: '128',
            numberOfInstructors: '754',
            button: <button className="AllQuestion-QuestionButton" onClick={<Allquestion />} style={{ cursor: 'pointer' }}><span className="NewQuestion-QuestionButton-Text">Sorular</span></button>,
        },
        {
            key: '8',
            lessonName: <div className="AllQuestion-LessonName">Türk Edebiyatı</div>,
            totalQuestions: '112.598',
            unansweredQuestions: '128',
            numberOfInstructors: '754',
            button: <button className="AllQuestion-QuestionButton" onClick={<Allquestion />} style={{ cursor: 'pointer' }}><span className="NewQuestion-QuestionButton-Text">Sorular</span></button>,
        },
        {
            key: '9',
            lessonName: <div className="AllQuestion-LessonName">Tarih</div>,
            totalQuestions: '112.598',
            unansweredQuestions: '128',
            numberOfInstructors: '754',
            button: <button className="AllQuestion-QuestionButton" onClick={<Allquestion />} style={{ cursor: 'pointer' }}><span className="AllQuestion-QuestionButton-Text">Sorular</span></button>,
        },
        {
            key: '10',
            lessonName: <div className="AllQuestion-LessonName">Coğrafya</div>,
            totalQuestions: '112.598',
            unansweredQuestions: '128',
            numberOfInstructors: '754',
            button: <button className="AllQuestion-QuestionButton" onClick={<Allquestion />} style={{ cursor: 'pointer' }}><span className="AllQuestion-QuestionButton-Text">Sorular</span></button>,
        },


    ];



    return (

        <div>
            <div>

                <Table
                    columns={ListColumns}
                    dataSource={ListData}
                />

            </div>


        </div>

    );
};

export default Allquestion;
