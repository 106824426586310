import React, { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import './newquestion.scss';
import { GetWord } from "../../../../utils";
import { Table } from "antd";

const Newquestion = () => {

  const [pageName, setPageName] = useOutletContext();

  setPageName(GetWord("Yeni Sorular"));

  const ListColumns = [
    {
      title:
        <div className="NewQuestion-LessonName">Ders Adı</div>,
      dataIndex: 'lessonName',

    },
    {
      title: 'Toplam Soru Sayısı',
      dataIndex: 'totalQuestions',
    },
    {
      title: 'Cevaplanmayan Soru Sayısı',
      dataIndex: 'unansweredQuestions',
    },
    {
      title: 'Eğitmen Sayısı',
      dataIndex: 'numberOfInstructors',
    },
    {
      title: 'İşlemler',
      dataIndex: 'button',
    },
  ];

  const ListData = [
    {
      key: '1',
      lessonName: <div className="NewQuestion-LessonName">Türkçe</div>,
      totalQuestions: '112.598',
      unansweredQuestions: '128',
      numberOfInstructors: '754',
      button: <button className="NewQuestion-QuestionButton" onClick={<Newquestion />} style={{ cursor: 'pointer' }}><span className="NewQuestion-QuestionButton-Text">Sorular</span></button>,
    },
    {
      key: '2',
      lessonName: <div className="NewQuestion-LessonName">Matematik</div>,
      totalQuestions: '112.598',
      unansweredQuestions: '128',
      numberOfInstructors: '754',
      button: <button className="NewQuestion-QuestionButton" onClick={<Newquestion />} style={{ cursor: 'pointer' }}><span className="NewQuestion-QuestionButton-Text">Sorular</span></button>,
    },
    {
      key: '3',
      lessonName: <div className="NewQuestion-LessonName">Fen Bilgisi</div>,
      totalQuestions: '112.598',
      unansweredQuestions: '128',
      numberOfInstructors: '754',
      button: <button className="NewQuestion-QuestionButton" onClick={<Newquestion />} style={{ cursor: 'pointer' }}><span className="NewQuestion-QuestionButton-Text">Sorular</span></button>,
    },
    {
      key: '4',
      lessonName: <div className="NewQuestion-LessonName">Sosyal Bilgiler</div>,
      totalQuestions: '112.598',
      unansweredQuestions: '128',
      numberOfInstructors: '754',
      button: <button className="NewQuestion-QuestionButton" onClick={<Newquestion />} style={{ cursor: 'pointer' }}><span className="NewQuestion-QuestionButton-Text">Sorular</span></button>,
    },
    {
      key: '5',
      lessonName: <div className="NewQuestion-LessonName">Fizik</div>,
      totalQuestions: '112.598',
      unansweredQuestions: '128',
      numberOfInstructors: '754',
      button: <button className="NewQuestion-QuestionButton" onClick={<Newquestion />} style={{ cursor: 'pointer' }}><span className="NewQuestion-QuestionButton-Text">Sorular</span></button>,
    },
    {
      key: '6',
      lessonName: <div className="NewQuestion-LessonName">Kimya</div>,
      totalQuestions: '112.598',
      unansweredQuestions: '128',
      numberOfInstructors: '754',
      button: <button className="NewQuestion-QuestionButton" onClick={<Newquestion />} style={{ cursor: 'pointer' }}><span className="NewQuestion-QuestionButton-Text">Sorular</span></button>,
    },
    {
      key: '7',
      lessonName: <div className="NewQuestion-LessonName">Biyoloji</div>,
      totalQuestions: '112.598',
      unansweredQuestions: '128',
      numberOfInstructors: '754',
      button: <button className="NewQuestion-QuestionButton" onClick={<Newquestion />} style={{ cursor: 'pointer' }}><span className="NewQuestion-QuestionButton-Text">Sorular</span></button>,
    },
    {
      key: '8',
      lessonName: <div className="NewQuestion-LessonName">Türk Edebiyatı</div>,
      totalQuestions: '112.598',
      unansweredQuestions: '128',
      numberOfInstructors: '754',
      button: <button className="NewQuestion-QuestionButton" onClick={<Newquestion />} style={{ cursor: 'pointer' }}><span className="NewQuestion-QuestionButton-Text">Sorular</span></button>,
    },
    {
      key: '9',
      lessonName: <div className="NewQuestion-LessonName">Tarih</div>,
      totalQuestions: '112.598',
      unansweredQuestions: '128',
      numberOfInstructors: '754',
      button: <button className="NewQuestion-QuestionButton" onClick={<Newquestion />} style={{ cursor: 'pointer' }}><span className="NewQuestion-QuestionButton-Text">Sorular</span></button>,
    },
    {
      key: '10',
      lessonName: <div className="NewQuestion-LessonName">Coğrafya</div>,
      totalQuestions: '112.598',
      unansweredQuestions: '128',
      numberOfInstructors: '754',
      button: <button className="NewQuestion-QuestionButton" onClick={<Newquestion />} style={{ cursor: 'pointer' }}><span className="NewQuestion-QuestionButton-Text">Sorular</span></button>,
    },


  ];



  return (

    <div>
      <div>

        <Table
          columns={ListColumns}
          dataSource={ListData}
        />

      </div>


    </div>

  );
};

export default Newquestion;
