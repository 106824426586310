import React from "react";
import './PaymentRequests.scss'
import { Link, useOutletContext } from "react-router-dom";
import { GetWord } from "../../../../utils";
import { Table } from "antd";

const PaymentRequests = () => {

  const [pageName, setPageName] = useOutletContext();
  setPageName(GetWord("Ödeme Talepleri"));

  const ListColumns = [
    {
      title: 'Adı Soyadı',
      dataIndex: 'studentName',
    },
    {
      title: 'Banka Adı',
      dataIndex: 'bankName',
    },
    {
      title: 'IBAN Numarası Fotoğrafı',
      dataIndex: 'iban',
    },
    {
      title: 'Tutar',
      dataIndex: 'amount',
    },
    {
      title: 'Tarih',
      dataIndex: 'date',
    },

    {
      title: 'İşlem',
      dataIndex: 'button',
    },
  ];


  const ListData = [
    {
      studentName: 'Mehmet Ali KARA',
      bankName: 'Ziraat Bankası',

      iban: 'TR76000990134567800100001',
      amount: '88.08₺',
      date: '28.12.2021(8 gün kaldı)',

      button: <div className="PaymentRequests-Button">
        <div className="PaymentRequests-Button-PaymentTeacher" >
          <button className="PaymentRequests-Button-PaymentTeacher-PaymentCompleted" onClick={<PaymentRequests />} style={{ cursor: 'pointer' }}><span className="PaymentRequests-Button-PaymentTeacher-Text">Ödeme Yapıldı</span> </button>
          <button className="PaymentRequests-Button-PaymentTeacher-SeeTheTeacher" onClick={<PaymentRequests />} style={{ cursor: 'pointer' }}><span className="PaymentRequests-Button-PaymentTeacher-Text">Eğitmeni Gör</span></button> </div>
      </div>,
    },
  ];


  return <div className="PaymentRequests">
    <div className="PaymentRequests-Button-PastPayments">


      <button className="PaymentRequests-Button-PastPayments-Style" style={{ cursor: 'pointer' }}>
        <div className="PaymentRequests-Button-PastPayments-Style-Icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
            <defs>
              <clipPath id="rmqhwapzha">
                <path data-name="Rectangle 413" transform="translate(1538 193)" d="M0 0h25v25H0z" />
              </clipPath>
            </defs>
            <g data-name="Mask Group 33" transform="translate(-1538 -193)">
              <g data-name="alarm-clock (4)">
                <g data-name="Group 121">
                  <g data-name="Group 120">
                    <path data-name="Path 70" d="M19.572 20.169a9.822 9.822 0 1 0-14.183 0l-2.552 3.4a.893.893 0 1 0 1.429 1.071l2.473-3.3a9.749 9.749 0 0 0 11.483 0l2.473 3.3a.893.893 0 1 0 1.429-1.071zm-6.2-6.777a.893.893 0 0 1-.179.536L10.516 17.5a.893.893 0 1 1-1.429-1.071l2.5-3.333V8.035a.893.893 0 1 1 1.786 0v5.357z" transform="translate(1538 193)" />
                  </g>
                </g>
                <g data-name="Group 123">
                  <g data-name="Group 122">
                    <path data-name="Path 71" d="M7.6 1.307A4.464 4.464 0 0 0 1.287 7.62a.893.893 0 0 0 1.263 0L7.6 2.57a.893.893 0 0 0 0-1.263z" transform="translate(1538 193)" />
                  </g>
                </g>
                <g data-name="Group 125">
                  <g data-name="Group 124">
                    <path data-name="Path 72" d="M23.673 1.307a4.464 4.464 0 0 0-6.313 0 .893.893 0 0 0 0 1.263l5.05 5.05a.893.893 0 0 0 1.263 0 4.464 4.464 0 0 0 0-6.313z" transform="translate(1538 193)" />
                  </g>
                </g>
              </g>
            </g>
          </svg></div>
        <span className="PaymentRequests-Button-PastPayments-Style-Text">Geçmiş Ödemeler</span>
      </button>





    </div>

    <div className="PaymentRequests-List">

      <Table
        columns={ListColumns}
        dataSource={ListData}
      />

    </div>


  </div>;
};

export default PaymentRequests;
