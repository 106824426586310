import React from "react";
import './reportquestion.scss';
import { useOutletContext } from "react-router-dom";
import { GetWord } from "../../../../utils";
import { Table } from "antd"

const Reportquestion = () => {
  const [pageName, setPageName] = useOutletContext();
  setPageName(GetWord("Raporlanan Sorular"));

  const ListColumns = [
    {
      title: 'Adı Soyadı',
      dataIndex: 'studentName',
    },
    {
      title: 'Eğitmen Adı',
      dataIndex: 'teacherName',
    },
    {
      title: 'Soru Fotoğrafı',
      dataIndex: 'questionPhoto',
    },

    {
      title: 'Rapor Sebebi',
      dataIndex: 'reasonOfReport',
    },
    {
      title: 'Yorum',
      dataIndex: 'comment',
    },
    {
      title: 'Şık',
      dataIndex: 'choice',
    },
    {
      title: 'Tarih',
      dataIndex: 'date',
    },
    {
      title: 'İşlem',
      dataIndex: 'button',
    },
  ];

  const ListData = [
    {
      key: '1',
      studentName: 'Mehmet Ali KARA',
      teacherName: 'Mali KARA',
      questionPhoto: <img className="ReportQuestions-QuestionPhoto"
        src='https://lh3.googleusercontent.com/q-Ag6tqk1BGvUHvq6HxPEEnBh_n9BxWoSp3k0gDNyyYDc_YCwJdOQq7Pcs35yjA0CVlv2koGkYWsw5iJBPuFQxv3I250TU-syesKmoAE0-Fzckvd7-PCORyTDUZUlQUeuH3RVFkn=s0' alt="" />,
      reasonOfReport: 'Kopya',
      comment: 'Hızlı Çözüm',
      choice: 'D',
      date: '12.05.2021 12.54',
      button: <div className="ReportQuestions-Button">
        <button className="ReportQuestions-Button-SeeQuestion" onClick={<Reportquestion />} style={{ cursor: 'pointer' }}><span className="ReportQuestions-Button-Text">Soruyu Gör</span> </button>
        <button className="ReportQuestions-Button-Delete" onClick={<Reportquestion />} style={{ cursor: 'pointer' }}><span className="ReportQuestions-Button-Text">Sil</span></button>
      </div>,
    },
    {
      key: '2',
      studentName: 'Mehmet Ali KARA',
      teacherName: 'Mali KARA',
      questionPhoto: <img className="ReportQuestions-QuestionPhoto"
        src='https://lh3.googleusercontent.com/q-Ag6tqk1BGvUHvq6HxPEEnBh_n9BxWoSp3k0gDNyyYDc_YCwJdOQq7Pcs35yjA0CVlv2koGkYWsw5iJBPuFQxv3I250TU-syesKmoAE0-Fzckvd7-PCORyTDUZUlQUeuH3RVFkn=s0' alt="" />,
      reasonOfReport: 'Soru Dışı Görsel',
      comment: 'Hızlı Çözüm',
      choice: 'D',
      date: '12.05.2021 12.54',
      button: <div className="ReportQuestions-Button">
        <button className="ReportQuestions-Button-SeeQuestion" onClick={<Reportquestion />} style={{ cursor: 'pointer' }}><span className="ReportQuestions-Button-Text">Soruyu Gör</span> </button>
        <button className="ReportQuestions-Button-Delete" onClick={<Reportquestion />} style={{ cursor: 'pointer' }}><span className="ReportQuestions-Button-Text">Sil</span></button>
      </div>,
    },
    {
      key: '3',
      studentName: 'Mehmet Ali KARA',
      teacherName: 'Mali KARA',
      questionPhoto: <img className="ReportQuestions-QuestionPhoto"
        src='https://lh3.googleusercontent.com/q-Ag6tqk1BGvUHvq6HxPEEnBh_n9BxWoSp3k0gDNyyYDc_YCwJdOQq7Pcs35yjA0CVlv2koGkYWsw5iJBPuFQxv3I250TU-syesKmoAE0-Fzckvd7-PCORyTDUZUlQUeuH3RVFkn=s0' alt="" />,
      reasonOfReport: 'Kopya',
      comment: 'Hızlı Çözüm',
      choice: 'D',
      date: '12.05.2021 12.54',
      button: <div className="ReportQuestions-Button">
        <button className="ReportQuestions-Button-SeeQuestion" onClick={<Reportquestion />} style={{ cursor: 'pointer' }}><span className="ReportQuestions-Button-Text">Soruyu Gör</span> </button>
        <button className="ReportQuestions-Button-Delete" onClick={<Reportquestion />} style={{ cursor: 'pointer' }}><span className="ReportQuestions-Button-Text">Sil</span></button>
      </div>,
    },
    {
      key: '4',
      studentName: 'Mehmet Ali KARA',
      teacherName: 'Mali KARA',
      questionPhoto: <img className="ReportQuestions-QuestionPhoto"
        src='https://lh3.googleusercontent.com/q-Ag6tqk1BGvUHvq6HxPEEnBh_n9BxWoSp3k0gDNyyYDc_YCwJdOQq7Pcs35yjA0CVlv2koGkYWsw5iJBPuFQxv3I250TU-syesKmoAE0-Fzckvd7-PCORyTDUZUlQUeuH3RVFkn=s0' alt="" />,
      reasonOfReport: 'Soru Dışı Görsel',
      comment: 'Hızlı Çözüm',
      choice: 'D',
      date: '12.05.2021 12.54',
      button: <div className="ReportQuestions-Button">
        <button className="ReportQuestions-Button-SeeQuestion" onClick={<Reportquestion />} style={{ cursor: 'pointer' }}><span className="ReportQuestions-Button-Text">Soruyu Gör</span> </button>
        <button className="ReportQuestions-Button-Delete" onClick={<Reportquestion />} style={{ cursor: 'pointer' }}><span className="ReportQuestions-Button-Text">Sil</span></button>
      </div>,
    },
    {
      key: '5',
      studentName: 'Mehmet Ali KARA',
      teacherName: 'Mali KARA',
      questionPhoto: <img className="ReportQuestions-QuestionPhoto"
        src='https://lh3.googleusercontent.com/q-Ag6tqk1BGvUHvq6HxPEEnBh_n9BxWoSp3k0gDNyyYDc_YCwJdOQq7Pcs35yjA0CVlv2koGkYWsw5iJBPuFQxv3I250TU-syesKmoAE0-Fzckvd7-PCORyTDUZUlQUeuH3RVFkn=s0' alt="" />,
      reasonOfReport: 'Kopya',
      comment: 'Hızlı Çözüm',
      choice: 'D',
      date: '12.05.2021 12.54',
      button: <div className="ReportQuestions-Button">
        <button className="ReportQuestions-Button-SeeQuestion" onClick={<Reportquestion />} style={{ cursor: 'pointer' }}><span className="ReportQuestions-Button-Text">Soruyu Gör</span> </button>
        <button className="ReportQuestions-Button-Delete" onClick={<Reportquestion />} style={{ cursor: 'pointer' }}><span className="ReportQuestions-Button-Text">Sil</span></button>
      </div>,
    },
  ];



  return (

    <div>
      <div className="ReportQuestions-All">
        <div></div>

        <button className="ReportQuestions-All-Button" onClick={<Reportquestion />} style={{ cursor: 'pointer' }}>
          <span className="ReportQuestions-All-Button-Text">Tümünü Gör</span>
        </button>


      </div>
      <div>

        <Table
          columns={ListColumns}
          dataSource={ListData}
        />
      </div>
    </div>



  );
};

export default Reportquestion;
