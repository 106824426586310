import { initializeApp } from "firebase/app";
import {
  signInWithEmailAndPassword,
  getAuth,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  sendPasswordResetEmail,
  signInWithCustomToken,
} from "firebase/auth";
import { toast } from "react-toastify";
import stores from "../stores";
import { setlogin, setlogout, setToken } from "../stores/auth";
import { Encrypt } from "./encrypted";
import { doc, getFirestore, onSnapshot, setDoc } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDB_0RAbnLDH62eSPignfdPiD9VxoO1bpc",
  authDomain: "studiateacher.firebaseapp.com",
  databaseURL: "https://studiateacher-default-rtdb.firebaseio.com",
  projectId: "studiateacher",
  storageBucket: "studiateacher.appspot.com",
  messagingSenderId: "225756518547",
  appId: "1:225756518547:web:5ae99b8e64a2a7ae32d566",
  measurementId: "G-XL3CWZ2JJR",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

export const login = async (email, password) => {
  try {
    const { user } = await signInWithEmailAndPassword(auth, email, password);
    return user;
  } catch (error) {
    console.log(error.code);
    toast.error(error.code);
  }
};

onAuthStateChanged(auth, (user) => {
  if (user) {
    user
      .getIdToken()
      .then((res) => { })
      .catch((err) => {
        stores.dispatch(setlogout());
      });
    stores.dispatch(setlogin(Encrypt(JSON.stringify(user))));
    stores.dispatch(setToken(Encrypt(JSON.stringify(user.accessToken))));
    console.log(user.accessToken)
  } else {
    stores.dispatch(setlogout());
  }
});

// export const RefleshToken = () => {
//   onAuthStateChanged(auth, (user) => {
//     if (user) {
//       user
//         .getIdToken()
//         .then((res) => {})
//         .catch((err) => {
//           stores.dispatch(setlogout());
//         });
//       stores.dispatch(setlogin(Encrypt(JSON.stringify(user))));
//       stores.dispatch(setToken(Encrypt(JSON.stringify(user.accessToken))));
//     } else {
//       stores.dispatch(setlogout());
//     }
//   });
// };

export const signoutUser = async () => {
  try {
    return await signOut(auth);
  } catch (error) {
    toast.error(error.code);
  }
};

export const userResetPassword = (email) => {
  sendPasswordResetEmail(auth, email)
    .then(() => {
      return true;
    })
    .catch((error) => {
      toast.error(error.code);
    });
};

export const singUpUser = async (email, password) => {
  try {
    const { user } = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    return user;
  } catch (error) {
    toast.error(error.code);
  }
};

export const RefleshToken = async (refreshToken) => {
  signInWithCustomToken(refreshToken)
    .then((userCredential) => {
      // Yenilenmiş erişim belgesi
      var newAccessToken = userCredential.user.getIdToken();
      stores.dispatch(setToken(Encrypt(JSON.stringify(newAccessToken))));
      console.log("Yenilenmiş Erişim Belgesi: ", newAccessToken);
      return userCredential.user;
    })
    .catch((error) => {
      // Hata işleme
      console.error("Hata: ", error);
      stores.dispatch(setlogout());
    });
};

export const setUserData = async (
  user,
  displayName,
  referenceCode,
  hasReferenceCode,
  email,
  phone
) => {
  await setDoc(doc(db, "users", user.uid), {
    uid: user.id,
    displayName,
    email,
    referenceCode,
    hasReferenceCode,
    phone,
  });
};

export const ImgUpload = async (file, folderName, filename) => {
  const imageRef = ref(storage, folderName + filename);
  try {
    await uploadBytes(imageRef, file);
    const responseFileUrl = await getDownloadURL(imageRef);
    return responseFileUrl;
  } catch (error) {
    toast.error("error", error.message);
  }
};

export default app;
