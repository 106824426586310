import React from 'react'
import './Lessons.scss';
import { Table } from 'antd';
import { useOutletContext } from 'react-router-dom';
import { GetWord } from '../../../../../utils';

const Lessons = () => {
    const [pageName, setPageName] = useOutletContext();
    setPageName(GetWord("Matematik"));

    const ListColumns = [
        {
            title: 'Adı Soyadı',
            dataIndex: 'studentName',
        },
        {
            title: 'Eğitmen Adı',
            dataIndex: 'teacherName',
        },
        {
            title: 'Soru Fotoğrafı',
            dataIndex: 'questionPhoto',
        },
        {
            title: 'Cevap Fotoğrafı',
            dataIndex: 'solitionPhoto',
        },

        {
            title: 'Puan',
            dataIndex: 'point',
        },
        {
            title: 'Yorum',
            dataIndex: 'comment',
        },
        {
            title: 'Şık',
            dataIndex: 'option',
        },
        {
            title: 'Tarih',
            dataIndex: 'date',
        },
        {
            title: 'Cevaplandı mı?',
            dataIndex: 'answered',
        },
        {
            title: 'İşlem',
            dataIndex: 'button',
        },
    ];


    const ListData = [
        {
            studentName: 'Mehmet Ali KARA',
            teacherName: 'Mehmet Ali KARA',
            questionPhoto: <img className="Lessons-QuestionPhoto"
                src='https://lh3.googleusercontent.com/q-Ag6tqk1BGvUHvq6HxPEEnBh_n9BxWoSp3k0gDNyyYDc_YCwJdOQq7Pcs35yjA0CVlv2koGkYWsw5iJBPuFQxv3I250TU-syesKmoAE0-Fzckvd7-PCORyTDUZUlQUeuH3RVFkn=s0' alt="" />,
            solitionPhoto: <img className="Lessons-QuestionPhoto"
                src='https://lh3.googleusercontent.com/q-Ag6tqk1BGvUHvq6HxPEEnBh_n9BxWoSp3k0gDNyyYDc_YCwJdOQq7Pcs35yjA0CVlv2koGkYWsw5iJBPuFQxv3I250TU-syesKmoAE0-Fzckvd7-PCORyTDUZUlQUeuH3RVFkn=s0' alt="" />,

            point: '100',
            comment: 'Hızlı Çözün',
            option: 'D',
            date: '12.05.2021 12.54',
            answered: 'Evet',
            button: <div className="Lessons-Button">
                <div className="Lessons-Button-DetailDelete" >
                    <button className="Lessons-Button-DetailDelete-Detail" onClick={<Lessons />} style={{ cursor: 'pointer' }}><span className="Lessons-Button-DetailDelete-Text">Detay</span> </button>
                    <button className="Lessons-Button-DetailDelete-Delete" onClick={<Lessons />} style={{ cursor: 'pointer' }}><span className="Lessons-Button-DetailDelete-Text">Sil</span></button> </div>
            </div>,
        },
    ];







    return (
        <div className='Lessons'>
            <div className='Lessons-List'>

                <Table
                    columns={ListColumns}
                    dataSource={ListData}
                />


            </div>


        </div>
    )
}

export default Lessons