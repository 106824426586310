import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { setPageName } from '../../../../stores/site';
import "./InstitutionalPlans.scss";
import { TiTick } from 'react-icons/ti';
import { FaRegCreditCard } from 'react-icons/fa';
import { BsTelephone } from 'react-icons/bs';
import { IoMailOutline } from 'react-icons/io5';
import { BiTimeFive } from 'react-icons/bi';
import { LiaTimesCircleSolid } from 'react-icons/lia';
import { array } from 'yup';
import InstitutionalPlansCard from './InstitutionalPlansCard';
import Service from '../../../../Service';
import Loading from '../../../shared/loading/loading';

const InstitutionalPlans = () => {
    const dispatch = useDispatch();
    dispatch(setPageName("Planlar"))
    const [loading, setLoading] = useState(true)


    const [OrganisationPackage, setOrganisationPackage] = useState();

    const getOrganisationPackage = () => {
        const service = new Service();
        service.Organisations.organisationPackage().then((res) => {
            setOrganisationPackage(res);
            setLoading(false)
        })

    }

    useEffect(() => {
        getOrganisationPackage();

    }, [])


    const PlanData = [
        {
            image: "https://cdn.zeplin.io/63245f2d80a7d510d2cf63d0/assets/9fc74902-1da9-4817-bede-18b4af29d1f2.svg",
            title: OrganisationPackage?.name,
            price: "10.000TL/ay",
            textArray: [{
                name: OrganisationPackage?.description,
            },

            ]
        },
        // {
        //     image: "https://cdn.zeplin.io/63245f2d80a7d510d2cf63d0/assets/d3879f5b-4fb1-40c2-ab02-e68993db6577.svg",
        //     title: "PAKET - 2",
        //     textArray: [{
        //         name: "1000 öğrenciye kadar kurumlar"
        //     },
        //     {
        //         name: "Aylık 100 soru hakkı (her öğrenciye)"
        //     },
        //     {
        //         name: "6 aylık soru çözüm hizmeti"
        //     },
        //     {
        //         name: "Detaylı istatiklikler"
        //     },
        //     {
        //         name: "Soru analizleri"
        //     },
        //     {
        //         name: "Öğrenci analizleri"
        //     },
        //     ]
        // },
        // {
        //     image: "https://cdn.zeplin.io/63245f2d80a7d510d2cf63d0/assets/29469541-2964-427c-b1f6-c7e083f23360.svg",
        //     title: "ÖZEL",
        //     textArray: [{
        //         name: "Tercih ettiğiniz öğrenci sayısı"
        //     },
        //     {
        //         name: "Tercih ettiğiniz soru sayısı"
        //     },
        //     {
        //         name: "Tercih ettiğiniz süre"
        //     },
        //     {
        //         name: "Detaylı istatiklikler"
        //     },
        //     {
        //         name: "Soru analizleri"
        //     },
        //     {
        //         name: "Öğrenci analizleri"
        //     },
        //     ]
        // },
    ]

    if (loading)
        return <Loading />

    return (
        <div>
            <div className='Plans'>
                {
                    PlanData.map((item, i) => {
                        // return <InstitutionalPlansCard item={item} key={i} index={i} />

                        return <InstitutionalPlansCard item={item} key={item} index={i} />

                    }
                    )
                }

            </div>

            <div className='Plans'>
                <div className='Plans-PaymentMethods'>
                    <img src='https://cdn.zeplin.io/63245f2d80a7d510d2cf63d0/assets/4f79117c-397e-4c3f-9a8e-a2cab924bc1d.svg' alt="" className='Plans-PaymentMethods-Image' />
                    <p className='Plans-PaymentMethods-Title'>Ödeme Yöntemleri</p>
                    <p className='Plans-PaymentMethods-CreditCard'>  <FaRegCreditCard className='Plans-PaymentMethods-CreditCard-Icon' /> <span className='Plans-PaymentMethods-CreditCard-Text'>Kredi Kartı/Banka Kartı</span></p>
                    <p className='Plans-PaymentMethods-CreditCard'> <img src="https://cdn.zeplin.io/63245f2d80a7d510d2cf63d0/assets/f09fca55-ae95-45c9-9b8f-6c2b1668cbdf.svg" alt="" className='Plans-PaymentMethods-CreditCard-Icon' /><span className='Plans-PaymentMethods-CreditCard-Text'>EFT / Havale</span></p>
                </div>
                <div className='Plans-PaymentMethods'>
                    <img src='https://cdn.zeplin.io/63245f2d80a7d510d2cf63d0/assets/fd090752-94ec-4227-a825-c2c420a775a7.svg' alt="" className='Plans-PaymentMethods-Image' />
                    <p className='Plans-PaymentMethods-Title'>İletişim Bilgileri</p>
                    <p className='Plans-PaymentMethods-CreditCard'>  <BsTelephone className='Plans-PaymentMethods-CreditCard-Icon' /> <span className='Plans-PaymentMethods-CreditCard-Text'>+90 222 222 2222</span></p>
                    <p className='Plans-PaymentMethods-CreditCard'> <IoMailOutline className='Plans-PaymentMethods-CreditCard-Icon' /><span className='Plans-PaymentMethods-CreditCard-Text'>kurumsal@studiapp.com</span></p>
                </div>
                <div className='Plans-PaymentMethods'>
                    <img src={process.env.PUBLIC_URL + "/Assets/images/search.png"} alt="" className='Plans-PaymentMethods-Image' />
                    <p className='Plans-PaymentMethods-Title'>Paket Bilgileri</p>
                    <p className='Plans-PaymentMethods-CreditCard'>  <BiTimeFive className='Plans-PaymentMethods-CreditCard-Icon' /> <span className='Plans-PaymentMethods-CreditCard-Text'>Aylık olarak yenilenir</span></p>
                    <p className='Plans-PaymentMethods-CreditCard'> <LiaTimesCircleSolid className='Plans-PaymentMethods-CreditCard-Icon' /><span className='Plans-PaymentMethods-CreditCard-Text'>Dilediğinde iptal et</span></p>
                </div>
            </div>
        </div>
    )
}

export default InstitutionalPlans