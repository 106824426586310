import React from 'react'
import './AddTest.scss'
import { Form, Tabs, message, Upload, Select, Input } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';
import CloudIcon from '../../../../../assets/images/cloud-computing.png';
import { Option } from 'antd/es/mentions';
import { useOutletContext } from 'react-router-dom';
import { GetWord } from '../../../../../utils';
import CurrentTest from './CurrentTest/CurrentTest';


const AddTest = () => {

    const [pageName, setPageName] = useOutletContext();
    setPageName(GetWord("Test Ekle"));


    const NewAdd = () => {

        const { Dragger } = Upload;
        const props = {
            name: 'file',
            multiple: true,
            action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
            onChange(info) {
                const { status } = info.file;
                if (status !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
                if (status === 'done') {
                    message.success(`${info.file.name} file uploaded successfully.`);
                } else if (status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
            onDrop(e) {
                console.log('Dropped files', e.dataTransfer.files);
            },
        };

        return (
            <div className='AddTest'>
                <div className='AddTest-NewAdd'>


                    <div className="AddTest-NewAdd-Lessons">
                        <Form.Item
                            name="lesson"
                            label="Ders"
                            rules={
                                [
                                    {
                                        required: true,
                                        message: 'Ders İçeriği Boş Bırakılamaz!',
                                    },
                                ]}>
                            <Select defaultValue="Option1" className='AddTest-NewAdd-Lessons-Input' >
                                <Option value="Option1"  >   </Option>
                            </Select>
                            <p className="AddTest-NewAdd-Lessons-Explanation">Konu Anlatımının dersini seçiniz</p>
                        </Form.Item>
                    </div>

                    <div className="AddTest-NewAdd-Subject">
                        <Form.Item
                            name="subject"
                            label="Konu"
                            rules={
                                [
                                    {
                                        required: true,
                                        message: 'Konu İçeriği Boş Bırakılamaz!',
                                    },
                                ]}>
                            <Select defaultValue="Option1" className='AddTest-NewAdd-Subject-Input' >
                                <Option value="Option1"  >   </Option>
                            </Select>
                            <p className="AddTest-NewAdd-Subject-Explanation">Konu Anlatımının konusunu seçiniz</p>
                        </Form.Item>
                    </div>

                    <div className="AddTest-NewAdd-NumberOfQuestion">
                        <Form.Item
                            name="numberOfQuestion"
                            label="Soru Sayısı"
                            rules={
                                [
                                    {
                                        required: true,
                                        message: 'Konu İçeriği Boş Bırakılamaz!',
                                    },
                                ]}>
                            <Select defaultValue="Option1" className='AddTest-NewAdd-NumberOfQuestion-Input' >
                                <Option value="Option1"  >   </Option>
                            </Select>
                            <p className="AddTest-NewAdd-NumberOfQuestion-Explanation">Testin soru sayısını seçiniz</p>
                        </Form.Item>
                    </div>


                    <div className="AddTest-NewAdd-QuestionPicture">
                        <Form.Item
                            name="quesitonImage"
                            label="Soru Resmi"
                            rules={
                                [
                                    {
                                        required: true,
                                        message: 'Soru Resmi İçeriği Boş Bırakılamaz!',
                                    },
                                ]}>
                            <Dragger {...props} className="AddTest-NewAdd-QuestionPicture-Upload" accept=".jpg, .jpeg, .png">
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Yüklemek için PNG veya JPEG dosyalarını buraya bırakın</p>
                                <p className="ant-upload-hint">
                                    <img src={CloudIcon} />
                                    <p className="AddTest-NewAdd-QuestionPicture-Upload-IconText">Yükle</p>

                                </p>
                            </Dragger>
                            <p className="AddTest-NewAdd-QuestionPicture-Upload-Explanation">Soruya ait bir resim ekleyin.</p>
                            <p className="AddTest-NewAdd-QuestionPicture-Upload-Explanation">Özellik grafiğiniz 1.024 x 500 piksel ölçülerinde, en fazla 1 MB'lık bir PNG veya JPEG dosyası olmalıdır</p>
                        </Form.Item>
                    </div>


                    <div className="AddTest-NewAdd-AnswerPicture">
                        <Form.Item
                            name="answerImage"
                            label="Cevap Resmi"
                            rules={
                                [
                                    {
                                        required: true,
                                        message: 'Cevap Resmi İçeriği Boş Bırakılamaz!',
                                    },
                                ]}>
                            <Dragger {...props} className="AddTest-NewAdd-AnswerPicture-Upload" accept=".jpg, .jpeg, .png">
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Yüklemek için PNG veya JPEG dosyalarını buraya bırakın</p>
                                <p className="ant-upload-hint">
                                    <img src={CloudIcon} />
                                    <p className="AddTest-NewAdd-AnswerPicture-Upload-IconText">Yükle</p>

                                </p>
                            </Dragger>
                            <p className="AddTest-NewAdd-AnswerPicture-Upload-Explanation">Cevaba ait bir resim ekleyin.</p>
                            <p className="AddTest-NewAdd-AnswerPicture-Upload-Explanation">Özellik grafiğiniz 1.024 x 500 piksel ölçülerinde, en fazla 1 MB'lık bir PNG veya JPEG dosyası olmalıdır</p>
                        </Form.Item>
                    </div>

                    <div className="AddTest-NewAdd-RightOption">
                        <Form.Item
                            name="rightOption"
                            label="Doğru Şık"
                            rules={
                                [
                                    {
                                        required: true,
                                        message: 'Doğru Şık İçeriği Boş Bırakılamaz!',
                                    },
                                ]}>
                            <Select defaultValue="Option1" className='AddTest-NewAdd-RightOption-Input' >
                                <Option value="Option1"  >   </Option>
                            </Select>
                            <p className="AddTest-NewAdd-RightOption-Explanation">Sorunun doğru cevabını seçiniz</p>
                        </Form.Item>
                    </div>

                    <button className='AddTest-NewAdd-Button'>
                        <span className='AddTest-NewAdd-Button-Text'>Ekle</span>
                    </button>

                </div>
            </div>
        )

    };



    const Available = () => {



        const CurrentTestData = [
            {
                image: "https://e1.pxfuel.com/desktop-wallpaper/460/879/desktop-wallpaper-geography-geography-pc-backgrounds-geography.jpg",
                lessonName: "Coğrafya",
                dateIcon: <svg data-name="vuesax/bulk/clock" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 13.931 13.931">
                    <path d="M11.609 5.8A5.8 5.8 0 1 1 5.8 0a5.8 5.8 0 0 1 5.809 5.8z" transform="translate(1.161 1.161)" style={{ opacity: 0.4, fill: 'currentColor' }} />
                    <path data-name="Vector" d="M2.8 5.323a.389.389 0 0 1-.221-.064L.778 4.185A1.712 1.712 0 0 1 0 2.815V.435A.439.439 0 0 1 .435 0a.439.439 0 0 1 .436.435v2.38a.858.858 0 0 0 .354.621l1.8 1.074a.435.435 0 0 1 .151.6.447.447 0 0 1-.376.213z" transform="translate(6.321 3.924)" style={{ fill: 'currentColor' }} />
                </svg>
                ,
                date: "12.07.2022",
                subject: "Yer Şekilleri",
                section: "Test",
            },
            {
                image: "https://e1.pxfuel.com/desktop-wallpaper/460/879/desktop-wallpaper-geography-geography-pc-backgrounds-geography.jpg",
                lessonName: "Coğrafya",
                dateIcon: <svg data-name="vuesax/bulk/clock" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 13.931 13.931">
                    <path d="M11.609 5.8A5.8 5.8 0 1 1 5.8 0a5.8 5.8 0 0 1 5.809 5.8z" transform="translate(1.161 1.161)" style={{ opacity: 0.4, fill: 'currentColor' }} />
                    <path data-name="Vector" d="M2.8 5.323a.389.389 0 0 1-.221-.064L.778 4.185A1.712 1.712 0 0 1 0 2.815V.435A.439.439 0 0 1 .435 0a.439.439 0 0 1 .436.435v2.38a.858.858 0 0 0 .354.621l1.8 1.074a.435.435 0 0 1 .151.6.447.447 0 0 1-.376.213z" transform="translate(6.321 3.924)" style={{ fill: 'currentColor' }} />
                </svg>
                ,
                date: "12.07.2022",
                subject: "Yer Şekilleri",
                section: "Test",
            },
            {
                image: "https://e1.pxfuel.com/desktop-wallpaper/460/879/desktop-wallpaper-geography-geography-pc-backgrounds-geography.jpg",
                lessonName: "Coğrafya",
                dateIcon: <svg data-name="vuesax/bulk/clock" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 13.931 13.931">
                    <path d="M11.609 5.8A5.8 5.8 0 1 1 5.8 0a5.8 5.8 0 0 1 5.809 5.8z" transform="translate(1.161 1.161)" style={{ opacity: 0.4, fill: 'currentColor' }} />
                    <path data-name="Vector" d="M2.8 5.323a.389.389 0 0 1-.221-.064L.778 4.185A1.712 1.712 0 0 1 0 2.815V.435A.439.439 0 0 1 .435 0a.439.439 0 0 1 .436.435v2.38a.858.858 0 0 0 .354.621l1.8 1.074a.435.435 0 0 1 .151.6.447.447 0 0 1-.376.213z" transform="translate(6.321 3.924)" style={{ fill: 'currentColor' }} />
                </svg>
                ,
                date: "12.07.2022",
                subject: "Yer Şekilleri",
                section: "Test",
            },
            {
                image: "https://e1.pxfuel.com/desktop-wallpaper/460/879/desktop-wallpaper-geography-geography-pc-backgrounds-geography.jpg",
                lessonName: "Coğrafya",
                dateIcon: <svg data-name="vuesax/bulk/clock" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 13.931 13.931">
                    <path d="M11.609 5.8A5.8 5.8 0 1 1 5.8 0a5.8 5.8 0 0 1 5.809 5.8z" transform="translate(1.161 1.161)" style={{ opacity: 0.4, fill: 'currentColor' }} />
                    <path data-name="Vector" d="M2.8 5.323a.389.389 0 0 1-.221-.064L.778 4.185A1.712 1.712 0 0 1 0 2.815V.435A.439.439 0 0 1 .435 0a.439.439 0 0 1 .436.435v2.38a.858.858 0 0 0 .354.621l1.8 1.074a.435.435 0 0 1 .151.6.447.447 0 0 1-.376.213z" transform="translate(6.321 3.924)" style={{ fill: 'currentColor' }} />
                </svg>
                ,
                date: "12.07.2022",
                subject: "Yer Şekilleri",
                section: "Test",
            },
        ]

        return (

            <div className='AddTest-CurrentTest'>

                {
                    CurrentTestData.map((item, i) => {

                        return <CurrentTest item={item} key={i} index={i} />
                    }
                    )
                }
            </div>




        )

    };





    const items = [
        {
            key: '1',
            label: 'Test Ekle',
            children: <NewAdd />,
        },
        {
            key: '2',
            label: 'Mevcut Testler',
            children: <Available />,
        },
    ];



    return (
        <div className='AddTest'>


            <div><Tabs defaultActiveKey="1" items={items} />
            </div>
        </div>
    )
}

export default AddTest