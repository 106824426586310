import React from 'react'
import InsSideBar from './components/InsSideBar/InsSideBar'
import "./scss/InstitutionalLayout.scss"
import InsHeader from './components/InsHeader/InsHeader'
import { Outlet } from 'react-router-dom'



const InstitutionalLayout = () => {

    return (

        <div className='InstitutionalLayout'>

            <InsSideBar />


            <div className="InstitutionalLayout-Main">
                <InsHeader />

                <main>
                    <Outlet />

                </main>

            </div>
        </div>

    )
}

export default InstitutionalLayout