import { NavLink } from "react-router-dom";
import "./InsSideBar.scss";
import SideBarItem from "./InsSideBarItem";
import React, { useEffect, useState } from 'react'
import { RxDashboard, RxPerson } from "react-icons/rx";
import { RiGraduationCapLine, RiSettings4Line } from "react-icons/ri";
import { BsClipboardData } from "react-icons/bs"
import { TiDocumentText } from "react-icons/ti"
import stats_icon from "../../../../assets/images/stats_icon.svg"
import plan_icon from "../../../../assets/images/plan_icon.svg"
import Service from "../../../../Service";


const InsSideBar = () => {

  const [data, setData] = useState();

  const getCompany = () => {
    const service = new Service();
    service.Organisations.organisations().then((res) => {
      setData(res);
      console.log(res);
    })
  }

  useEffect(() => { getCompany() }, [])


  const DataMenu = [
    {
      name: "Dashboard",
      icon: <RxDashboard className="InsSideBar-Nav-Link-Icon" />,
      url: "/institutional/dashboard"
    },
    {
      name: "Öğrenciler",
      icon: <RiGraduationCapLine className="InsSideBar-Nav-Link-Icon" />,
      url: "/institutional/students"
    },
    {
      name: "Öğretmenler",
      icon: <RxPerson className="InsSideBar-Nav-Link-Icon" />,
      url: "/institutional/teachers"
    },
    {
      name: "İstatistikler",
      icon: <img src={stats_icon} className="InsSideBar-Nav-Link-Icon" />,
      url: "/institutional/stats"
    },
    {
      name: "Planlar",
      icon: <img src={plan_icon} className="InsSideBar-Nav-Link-Icon" />,
      url: "/institutional/plans"
    },
    {
      name: "Ayarlar",
      icon: <RiSettings4Line className="InsSideBar-Nav-Link-Icon" />,
      url: "/institutional/settings"
    },

  ]


  return (
    <div className="InsSideBar">

      <p className="InsSideBar-StudiaLogo" >Studia
        <span className="InsSideBar-KurumsalLogo">Kurumsal</span>
      </p>
      <p className="InsSideBar-SchoolName">{data && data[0].name}</p>

      <nav className="InsSideBar-Nav">
        {
          DataMenu.map((item, index) => {
            return <SideBarItem key={index} item={item} />
          })
        }

      </nav>
    </div>

  )
}


export default InsSideBar