import { useDispatch } from 'react-redux';
import { setPageName } from '../../../../../stores/site';
import "./InstitutionalStudentAdd.scss"
import { LuCalendarDays } from "react-icons/lu";
import locale from 'antd/es/date-picker/locale/tr_TR';
import 'dayjs/locale/tr';
import {
    Button,
    DatePicker,
    Form,
    Input,

} from 'antd';




const InstitutionalStudentAdd = () => {
    const [form] = Form.useForm();

    const handleKeyPress = (e) => {
        // Sadece sayıları izin ver
        const isNumber = /^[0-9\b]+$/;
        if (!isNumber.test(e.key)) {
            e.preventDefault();
        }
    };

    const finish = (value) => {
        console.log(value)
    }

    const dispatch = useDispatch();
    dispatch(setPageName("Öğrenci Ekle"))

    return (
        <div>
            <Form form={form} onFinish={finish} name="basic" labelCol={{
                span: 8,
            }}
                wrapperCol={{
                    span: 18,
                }}>
                <div className='InsStudentAdd'>
                    <Form.Item
                        name="username"
                        label="Öğrenci Adı Soyadı:"
                        rules={[
                            {
                                required: true,
                                message: 'Öğrenci Adı Soyadı Boş Bırakılamaz!',
                            },
                        ]}>
                        <Input type='text' className='InsStudentAdd-Input' />
                    </Form.Item>
                </div>


                <div className='InsStudentAdd'>
                    <Form.Item
                        name="sda"
                        label="Öğrenci TC Kimlik No:"
                        rules={[
                            {
                                required: true,
                                message: 'Öğrenci TC Kimlik No Boş Bırakılamaz!',
                            },
                        ]}>
                        <Input type='text' onKeyPress={handleKeyPress} className='InsStudentAdd-Input' />
                    </Form.Item>
                </div>



                <div className='InsStudentAdd'>
                    <Form.Item
                        name="sdfsg"
                        label="Öğrenci Telefon No:"

                        rules={[
                            {
                                required: true,
                                message: 'Öğrenci Telefon No Boş Bırakılamaz!',
                            },
                        ]}>
                        <Input type="text" onKeyPress={handleKeyPress} className='InsStudentAdd-Input' />
                    </Form.Item>
                </div>


                <div className='InsStudentAdd'>
                    <Form.Item
                        name="sadsadsadsa"
                        label="Öğrenci Doğum Tarihi:"

                        rules={[
                            {
                                required: true,
                                message: 'Öğrenci Doğum Tarihi Boş Bırakılamaz!',
                            },
                        ]}>
                        <DatePicker format="DD/MM/YYYY" locale={locale} placeholder="" className='InsStudentAdd-Input anticon' suffixIcon={<LuCalendarDays />} />
                    </Form.Item>
                </div>

                <div className='InsStudentAdd-Button'>
                    <Form.Item>
                        <Button htmlType='submit' className="InsStudentAdd-Button-Style">Öğrenci Ekle</Button>
                    </Form.Item>
                </div>
            </Form>
        </div >
    )
}


export default InstitutionalStudentAdd