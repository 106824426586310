import React from "react";
import "./students.scss";
import { useOutletContext } from "react-router-dom";
import { GetWord } from "../../../../utils";
import * as AiIcons from "react-icons/ai";
import { Checkbox, Table } from "antd";

const Students = () => {
  const [pageName, setPageName] = useOutletContext();
  setPageName(GetWord("Öğrenciler"));

  const ListColumns = [
    {
      title: 'ID',
      dataIndex: 'ID',

    },
    {
      title: 'Kayıt Tarihi',
      dataIndex: 'dateOfRegistration',
    },
    {
      title: '',
      dataIndex: 'photo',
    },

    {
      title: 'İsim',
      dataIndex: 'name',
    },
    {
      title: 'E-Posta Adresi',
      dataIndex: 'eMail',
    },
    {
      title: 'Soru Hakkı',
      dataIndex: 'rightToQuestion',
    },
    {
      title: 'İşlem',
      dataIndex: 'button',
    },
  ];
  const ListData = [
    {
      key: '1',
      ID: '#001234',
      dateOfRegistration: '12.12.2022 - 12:58',
      photo: <img className="Students-Photo" src='https://img.freepik.com/free-photo/medium-shot-boy-portrait-with-graduation-background_23-2150293635.jpg' alt="" />,
      name: 'Mali KARA',
      eMail: 'deneme@deneme.com',
      rightToQuestion: '205',
      button: <button className="Students-DetailButton" onClick={<Students />} style={{ cursor: 'pointer' }}><span className="Students-DetailButton-Text">Detay</span></button>,
    },
    {
      key: '1',
      ID: '#001234',
      dateOfRegistration: '12.12.2022 - 12:58',
      photo: <img className="Students-Photo" src='https://img.freepik.com/free-photo/medium-shot-boy-portrait-with-graduation-background_23-2150293635.jpg' alt="" />,
      name: 'Mali KARA',
      eMail: 'deneme@deneme.com',
      rightToQuestion: '205',
      button: <button className="Students-DetailButton" onClick={<Students />} style={{ cursor: 'pointer' }}><span className="Students-DetailButton-Text">Detay</span></button>,
    },
    {
      key: '1',
      ID: '#001234',
      dateOfRegistration: '12.12.2022 - 12:58',
      photo: <img className="Students-Photo" src='https://img.freepik.com/free-photo/medium-shot-boy-portrait-with-graduation-background_23-2150293635.jpg' alt="" />,
      name: 'Mali KARA',
      eMail: 'deneme@deneme.com',
      rightToQuestion: '205',
      button: <button className="Students-DetailButton" onClick={<Students />} style={{ cursor: 'pointer' }}><span className="Students-DetailButton-Text">Detay</span></button>,
    },
    {
      key: '1',
      ID: '#001234',
      dateOfRegistration: '12.12.2022 - 12:58',
      photo: <img className="Students-Photo" src='https://img.freepik.com/free-photo/medium-shot-boy-portrait-with-graduation-background_23-2150293635.jpg' alt="" />,
      name: 'Mali KARA',
      eMail: 'deneme@deneme.com',
      rightToQuestion: '205',
      button: <button className="Students-DetailButton" onClick={<Students />} style={{ cursor: 'pointer' }}><span className="Students-DetailButton-Text">Detay</span></button>,
    },
    {
      key: '1',
      ID: '#001234',
      dateOfRegistration: '12.12.2022 - 12:58',
      photo: <img className="Students-Photo" src='https://img.freepik.com/free-photo/medium-shot-boy-portrait-with-graduation-background_23-2150293635.jpg' alt="" />,
      name: 'Mali KARA',
      eMail: 'deneme@deneme.com',
      rightToQuestion: '205',
      button: <button className="Students-DetailButton" onClick={<Students />} style={{ cursor: 'pointer' }}><span className="Students-DetailButton-Text">Detay</span></button>,
    },
    {
      key: '1',
      ID: '#001234',
      dateOfRegistration: '12.12.2022 - 12:58',
      photo: <img className="Students-Photo" src='https://img.freepik.com/free-photo/medium-shot-boy-portrait-with-graduation-background_23-2150293635.jpg' alt="" />,
      name: 'Mali KARA',
      eMail: 'deneme@deneme.com',
      rightToQuestion: '205',
      button: <button className="Students-DetailButton" onClick={<Students />} style={{ cursor: 'pointer' }}><span className="Students-DetailButton-Text">Detay</span></button>,
    },
    {
      key: '1',
      ID: '#001234',
      dateOfRegistration: '12.12.2022 - 12:58',
      photo: <img className="Students-Photo" src='https://img.freepik.com/free-photo/medium-shot-boy-portrait-with-graduation-background_23-2150293635.jpg' alt="" />,
      name: 'Mali KARA',
      eMail: 'deneme@deneme.com',
      rightToQuestion: '205',
      button: <button className="Students-DetailButton" onClick={<Students />} style={{ cursor: 'pointer' }}><span className="Students-DetailButton-Text">Detay</span></button>,
    },
    {
      key: '1',
      ID: '#001234',
      dateOfRegistration: '12.12.2022 - 12:58',
      photo: <img className="Students-Photo" src='https://img.freepik.com/free-photo/medium-shot-boy-portrait-with-graduation-background_23-2150293635.jpg' alt="" />,
      name: 'Mali KARA',
      eMail: 'deneme@deneme.com',
      rightToQuestion: '205',
      button: <button className="Students-DetailButton" onClick={<Students />} style={{ cursor: 'pointer' }}><span className="Students-DetailButton-Text">Detay</span></button>,
    },
    {
      key: '1',
      ID: '#001234',
      dateOfRegistration: '12.12.2022 - 12:58',
      photo: <img className="Students-Photo" src='https://img.freepik.com/free-photo/medium-shot-boy-portrait-with-graduation-background_23-2150293635.jpg' alt="" />,
      name: 'Mali KARA',
      eMail: 'deneme@deneme.com',
      rightToQuestion: '205',
      button: <button className="Students-DetailButton" onClick={<Students />} style={{ cursor: 'pointer' }}><span className="Students-DetailButton-Text">Detay</span></button>,
    },
    {
      key: '1',
      ID: '#001234',
      dateOfRegistration: '12.12.2022 - 12:58',
      photo: <img className="Students-Photo" src='https://img.freepik.com/free-photo/medium-shot-boy-portrait-with-graduation-background_23-2150293635.jpg' alt="" />,
      name: 'Mali KARA',
      eMail: 'deneme@deneme.com',
      rightToQuestion: '205',
      button: <button className="Students-DetailButton" onClick={<Students />} style={{ cursor: 'pointer' }}><span className="Students-DetailButton-Text">Detay</span></button>,
    },
    {
      key: '1',
      ID: '#001234',
      dateOfRegistration: '12.12.2022 - 12:58',
      photo: <img className="Students-Photo" src='https://img.freepik.com/free-photo/medium-shot-boy-portrait-with-graduation-background_23-2150293635.jpg' alt="" />,
      name: 'Mali KARA',
      eMail: 'deneme@deneme.com',
      rightToQuestion: '205',
      button: <button className="Students-DetailButton" onClick={<Students />} style={{ cursor: 'pointer' }}><span className="Students-DetailButton-Text">Detay</span></button>,
    },
    {
      key: '1',
      ID: '#001234',
      dateOfRegistration: '12.12.2022 - 12:58',
      photo: <img className="Students-Photo" src='https://img.freepik.com/free-photo/medium-shot-boy-portrait-with-graduation-background_23-2150293635.jpg' alt="" />,
      name: 'Mali KARA',
      eMail: 'deneme@deneme.com',
      rightToQuestion: '205',
      button: <button className="Students-DetailButton" onClick={<Students />} style={{ cursor: 'pointer' }}><span className="Students-DetailButton-Text">Detay</span></button>,
    },
    {
      key: '1',
      ID: '#001234',
      dateOfRegistration: '12.12.2022 - 12:58',
      photo: <img className="Students-Photo" src='https://img.freepik.com/free-photo/medium-shot-boy-portrait-with-graduation-background_23-2150293635.jpg' alt="" />,
      name: 'Mali KARA',
      eMail: 'deneme@deneme.com',
      rightToQuestion: '205',
      button: <button className="Students-DetailButton" onClick={<Students />} style={{ cursor: 'pointer' }}><span className="Students-DetailButton-Text">Detay</span></button>,
    },
    {
      key: '1',
      ID: '#001234',
      dateOfRegistration: '12.12.2022 - 12:58',
      photo: <img className="Students-Photo" src='https://img.freepik.com/free-photo/medium-shot-boy-portrait-with-graduation-background_23-2150293635.jpg' alt="" />,
      name: 'Mali KARA',
      eMail: 'deneme@deneme.com',
      rightToQuestion: '205',
      button: <button className="Students-DetailButton" onClick={<Students />} style={{ cursor: 'pointer' }}><span className="Students-DetailButton-Text">Detay</span></button>,
    },
    {
      key: '1',
      ID: '#001234',
      dateOfRegistration: '12.12.2022 - 12:58',
      photo: <img className="Students-Photo" src='https://img.freepik.com/free-photo/medium-shot-boy-portrait-with-graduation-background_23-2150293635.jpg' alt="" />,
      name: 'Mali KARA',
      eMail: 'deneme@deneme.com',
      rightToQuestion: '205',
      button: <button className="Students-DetailButton" onClick={<Students />} style={{ cursor: 'pointer' }}><span className="Students-DetailButton-Text">Detay</span></button>,
    },
    {
      key: '1',
      ID: '#001234',
      dateOfRegistration: '12.12.2022 - 12:58',
      photo: <img className="Students-Photo" src='https://img.freepik.com/free-photo/medium-shot-boy-portrait-with-graduation-background_23-2150293635.jpg' alt="" />,
      name: 'Mali KARA',
      eMail: 'deneme@deneme.com',
      rightToQuestion: '205',
      button: <button className="Students-DetailButton" onClick={<Students />} style={{ cursor: 'pointer' }}><span className="Students-DetailButton-Text">Detay</span></button>,
    },
  ];


  return (
    <div>
      <div>
        <div className="Students-Input">
          <input
            type="search"
            className="Students-Input-Style"
            placeholder={GetWord("Ara...")}
          />
          <span className="Students-Input-SearchLogo">
            <AiIcons.AiOutlineSearch className="Students-Input-SearchLogo-Style" />
          </span>
        </div>


        <div className="table">

          <Table
            rowSelection={{
              type: Checkbox,
            }}
            scroll={{ x: 'fit-content' }}
            columns={ListColumns}
            dataSource={ListData}
          />
        </div>



      </div>
    </div>

  );
};

export default Students;
