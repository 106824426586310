import { Button, DatePicker, Form, Input, Upload } from "antd";
import { locale } from "dayjs";
import React, { useEffect } from "react";
import "./notification.scss";
import { IoNotifications } from "react-icons/io5";
import { LuCalendarDays } from "react-icons/lu";
import { TiTick } from "react-icons/ti";
import { UploadOutlined } from '@ant-design/icons';
import { setPageName } from "../../../../stores/site";
import { GetWord } from "../../../../utils";
import { useOutletContext } from "react-router-dom";

const Notification = () => {
  const [pageName, setPageName] = useOutletContext();
  setPageName(GetWord("Bildirim Gönder"));

  const props = {
    action: '//jsonplaceholder.typicode.com/posts/',
    listType: 'picture',
    previewFile(file) {
      console.log('Your upload file:', file);
      // Your process logic. Here we just mock to the same file
      return fetch('https://next.json-generator.com/api/json/get/4ytyBoLK8', {
        method: 'POST',
        body: file,
      })
        .then((res) => res.json())
        .then(({ thumbnail }) => thumbnail);
    },
  };



  const [form] = Form.useForm();

  const finish = (value) => {
    console.log(value)
  }



  return (

    <div>
      <div className='Notification'>
        <div className='Notification-Area-FormText-NotificationTitle'>
          <Form.Item
            name="notificationTitle"
            label="Bildirim Başlığı"
            rules={[
              {
                required: true,
                message: 'Bildirim Başlığı Boş Bırakılamaz!',
              },
            ]}>
            <Input type='text' className='Notification-Area-FormText-NotificationTitle-Input' />
          </Form.Item>

        </div>

      </div>
      <p className="Notification-Area-FormText-NotificationTitle-Explanation">Bildirim başlığı olarak gösterilir.</p>

      <div className='Notification'>
        <div className='Notification-Area-FormText-NotificationTitle'>
          <Form.Item
            name="notificationContent"
            label="Bildirim İçeriği"
            rules={
              [
                {
                  required: true,
                  message: 'Bildirim İçeriği Boş Bırakılamaz!',
                },
              ]}>
            <Input.TextArea className='Notification-Area-FormText-NotificationContent-Input' autoSize={{
              minRows: 2,
              maxRows: 2,
            }} />
          </Form.Item>
        </div>
      </div>
      <p className="Notification-Area-FormText-NotificationContent-Explanation">Bildirim metni olarak gösterilir.</p>


      <div className='Notification'>
        <div className='Notification-Area-FormText-NotificationTitle'>
          <Form.Item
            name="notificationPicture"
            label="Bildirim Resmi"
            rules={
              [
                {
                  required: false,
                  message: 'Bildirim Başlığı Boş Bırakılamaz!',
                },
              ]}>
            <Input type='text' className='Notification-Area-FormText-NotificationPicture' />
            <Upload {...props}>
              <Button icon={<UploadOutlined />} className='Notification-Area-FormText-NotificationPicture-Button' ></Button>
            </Upload>

          </Form.Item>
        </div>
      </div>
      <p className="Notification-Area-FormText-NotificationPicture-Explanation">Bir URL veya resim ekleyin.</p>


      <div className='Notification-Area-FormText-DateButton'>
        <div className='Notification-Area-FormText-NotificationTitle'>
          <Form.Item
            name="notificationDate"
            label="Tarih"
            rules={
              [
                {
                  required: true,
                  message: 'Bildirim Tarihi Boş Bırakılamaz!',
                },
              ]}>

            <button className='Notification-Area-FormText-DateButton-Style'>Şimdi</button>
            <span className='Notification-Area-FormText-Date-Or'> ya da </span>
            <DatePicker format="DD/MM/YYYY" locale={locale} placeholder="" className='Notification-Area-FormText-Date' suffixIcon={<LuCalendarDays />} />
          </Form.Item>
        </div>
      </div>
      <p className="Notification-Area-FormText-Date-Explanation">Bildirim tarihi.</p>

      <div className='Notification'>
        <div className='Notification-Area-FormText-NotificationTitle'>


          <button className='Notification-Area-FormText-SentButton'>Gönder</button>
        </div>
      </div>


    </div >


  );
};

export default Notification;
