import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import AdminLayout from "../layout/admin/AdminLayout";
import Teachers from "../layout/admin/pages/teachers/teachers";
import Allquestion from "../layout/admin/pages/allquestion/allquestion";
import Dashboard from "../layout/admin/pages/dashboard/dashboard";
import Newquestion from "../layout/admin/pages/newquestion/newquestion";
import Notification from "../layout/admin/pages/notification/notification.jsx";
import { getLocalstorage } from "../utils";
import Public from "../layout/admin/pages/public/public";
import PaymentRequests from "../layout/admin/pages/PaymentRequests/PaymentRequests";
import Reportedfixed from "../layout/admin/pages/reportedfixed/reportedfixed";
import Reportquestion from "../layout/admin/pages/reportquestion/reportquestion.jsx";
import Sellpacket from "../layout/admin/pages/sellpacket/sellpacket";
import Story from "../layout/admin/pages/story/story";
import Students from "../layout/admin/pages/students/students";
import ErrorPage from "../layout/admin/pages/errors/ErrorPage";
import Applications from "../layout/admin/pages/teachers/applications/applications.jsx";
import TeachersDetails from "../layout/admin/pages/teachers/details/TeachersDetails.jsx";
import NewquestionDetails from "../layout/admin/pages/newquestion/details/QuestionsDetails.jsx";
import NewquestionLessons from "../layout/admin/pages/newquestion/lessons/Lessons.jsx";
import StudentsDetails from "../layout/admin/pages/students/details/StudentsDetails.jsx";
import AddSubject from "../layout/admin/pages/TestSubjectPage/AddSubject/AddSubject.jsx";
import AddTest from "../layout/admin/pages/TestSubjectPage/AddTest/AddTest.jsx";
import AddCard from "../layout/admin/pages/TestSubjectPage/AddCard/AddCard.jsx";
import TestSubject from "../layout/admin/pages/TestSubjectPage/TestSubject.jsx";
import JobDetail from "../layout/admin/pages/TestSubjectPage/JobDetail/JobDetail.jsx";


const PrivateRouter = () => {
  const navigate = useNavigate();
  const user = getLocalstorage("user");

  useEffect(() => {
    if (!user) {
      navigate("/auth");
    }
  }, [user, navigate]);
  return user ? (
    <Routes>
      <Route path="/" element={<AdminLayout />}>
        <Route index={true} element={<Dashboard />} />

        <Route path='/teachers'>
          <Route index element={<Teachers />} />
          <Route path='applications' element={<Applications />} />
          <Route path='details' element={<TeachersDetails />} />
        </Route>
        <Route path="allquestion" element={<Allquestion />} />
        <Route path='/newquestion'>
          <Route index element={<Newquestion />} />
          <Route path='lessons' element={<NewquestionLessons />} />
          <Route path='details' element={<NewquestionDetails />} />
        </Route>
        <Route path="notification" element={<Notification />} />
        <Route path="PaymentRequests" element={<PaymentRequests />} />
        <Route path="public" element={<Public />} />
        <Route path="reportedfixed" element={<Reportedfixed />} />
        <Route path="sellpacket" element={<Sellpacket />} />
        <Route path="story" element={<Story />} />
        <Route path="reportquestion" element={<Reportquestion />} />

        <Route path='/students'>
          <Route index element={<Students />} />
          <Route path='details' element={<StudentsDetails />} />
        </Route>

        <Route path='/TestSubject'>
          <Route index element={<TestSubject />} />
          <Route path='AddSubject' element={<AddSubject />} />
          <Route path='AddTest' element={<AddTest />} />
          <Route path='AddCard' element={<AddCard />} />
          <Route path='JobDetail' element={<JobDetail />} />
        </Route>

        <Route path="*" element={<ErrorPage />} />
      </Route>
    </Routes>
  ) : null;
};

export default PrivateRouter;
