import React, { useState } from "react";
import { GetWord, setLocalstorage } from "../../../utils";
import { useFormik } from "formik";
import validations from "../../../utils/validations";
import { RiLockPasswordLine } from "react-icons/ri";
import {
  MdOutlineVisibilityOff,
  MdOutlineVisibility,
  MdOutlineMail,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { json, Link, useLocation, useNavigate } from "react-router-dom";
import { login } from "../../../utils/firebase";

const Login = () => {
  const UrlName = window.location.hostname.includes("kurumsal");
  const navigate = useNavigate();
  const { handleSubmit, handleChange, handleBlur, values, errors, touched } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      onSubmit: async (values) => {
        const user = await login(values.email, values.password);
        if (user) {
          navigate("/");
        }
      },
      validationSchema: validations.loginValidations,
    });
  const [isVisable, SetIsVisable] = useState(false);

  const clickPass = () => {
    SetIsVisable((prev) => !prev);
  };

  return (
    <div className="row">
      <div className="col-12">
        <img src={process.env.PUBLIC_URL + "/Assets/images/logo.png"} alt="logo" />
        <h3>
          <span className="text-primary">Studia</span>
          <span className="text-secondary">{UrlName ? "Kurumsal" : "Admin"}</span>
        </h3>
        <h4>{GetWord("login")}</h4>
        <form onSubmit={handleSubmit}>
          <div className="col-12">
            <div className="mb-4">
              <div className="form-input">
                <label htmlFor="email">{GetWord("email")}</label>
                <div className="form-input-group">
                  <MdOutlineMail className="form-input-group-left" />
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder={GetWord("email")}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {errors.email && touched.email && (
                  <div className="form-input-text-error">{errors.email}</div>
                )}
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="mb-4">
              <div className="form-input">
                <label htmlFor="password">{GetWord("password")}</label>
                <div className="form-input-group">
                  <RiLockPasswordLine className="form-input-group-left" />
                  <input
                    type={isVisable ? "text" : "password"}
                    name="password"
                    className="form-control"
                    placeholder={GetWord("password")}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Link className="form-input-group-right" onClick={clickPass}>
                    {isVisable ? (
                      <MdOutlineVisibility />
                    ) : (
                      <MdOutlineVisibilityOff />
                    )}
                  </Link>
                </div>
                {errors.password && touched.password && (
                  <div className="form-input-text-error">{errors.password}</div>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 mb-4 d-flex justify-content-between">
            <div></div>
            <div>
              <Link to="/auth/resetpassword" className="text-secondary">
                {GetWord("IForgotMyPassword")}
              </Link>
            </div>
          </div>
          <div className="col-12">
            <button type="submit" className="btn btn-primary w-100">
              {GetWord("login")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
