import React, { useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom';
import InstitutionalLayout from '../layout/institutional/InstitutionalLayout';
import InstitutionalDashboard from '../layout/institutional/pages/dashboard/institutionalDashboard';
import { getLocalstorage } from '../utils';
import InstitutionalStudents from '../layout/institutional/pages/students/InstitutionalStudents';
import InstitutionalStudentsAdd from '../layout/institutional/pages/students/add/InstitutionalStudentAdd';
import InstitutionalTeachers from '../layout/institutional/pages/teachers/InstitutionalTeachers';
import InstitutionalPlans from '../layout/institutional/pages/plans/InstitutionalPlans';
import InstitutionalStats from '../layout/institutional/pages/stats/InstitutionalStats';
import InstitutionalSettings from '../layout/institutional/pages/settings/InstitutionalSettings';
import InstitutionalStudentDetail from '../layout/institutional/pages/students/details/InstitutionalStudentDetail';

const InstitutionalRouter = () => {
    const navigate = useNavigate();
    const user = getLocalstorage("user");

    useEffect(() => {
        if (!user) {
            navigate("/auth");
        }
    }, [user, navigate]);
    return user ? (
        <Routes>
            <Route path="/" element={<InstitutionalLayout />}>
                <Route path='/dashboard' index={true} element={<InstitutionalDashboard />} />
                <Route path='/students'>
                    <Route index element={<InstitutionalStudents />} />
                    <Route path='add' element={<InstitutionalStudentsAdd />} />
                    <Route path='details/:id' element={<InstitutionalStudentDetail />} />
                </Route>
                <Route path='/teachers' element={<InstitutionalTeachers />} />
                <Route path='/plans' element={<InstitutionalPlans />} />
                <Route path='/stats' element={<InstitutionalStats />} />
                <Route path='/settings' element={<InstitutionalSettings />} />

            </Route>
        </Routes>
    ) : null;
};

export default InstitutionalRouter