import React, { useState } from 'react'
import "./InstitutionalSettings.scss";
import { setPageName } from '../../../../stores/site';
import { useDispatch } from 'react-redux';
import { MdPhotoCamera } from "react-icons/md";
import { IoNotifications } from "react-icons/io5";
import { UploadOutlined } from '@ant-design/icons';
import { TiTick } from "react-icons/ti";
import {
    Button,
    DatePicker,
    Form,
    Input,
    Upload,
} from 'antd';
import locale from 'antd/es/date-picker/locale/tr_TR';
import 'dayjs/locale/tr';
import { Link } from 'react-router-dom';
import { LuCalendarDays } from 'react-icons/lu';
import Service from '../../../../Service';
import { useEffect } from 'react';

const InstitutionalSettings = () => {

    const [company, setCompany] = useState();



    const props = {
        action: '//jsonplaceholder.typicode.com/posts/',
        listType: 'picture',
        previewFile(file) {
            console.log('Your upload file:', file);
            // Your process logic. Here we just mock to the same file
            return fetch('https://next.json-generator.com/api/json/get/4ytyBoLK8', {
                method: 'POST',
                body: file,
            })
                .then((res) => res.json())
                .then(({ thumbnail }) => thumbnail);
        },
    };



    const [form] = Form.useForm();

    const finish = (value) => {
        console.log(value)
    }

    const dispatch = useDispatch();
    dispatch(setPageName("Ayarlar"))



    const getCompany = () => {
        const service = new Service();
        service.Organisations.organisations().then((res) => {
            setCompany(res);
        })
    }


    useEffect(() => {
        getCompany();
    }, [])


    return (
        <div className='Settings'>
            <Form form={form} onFinish={finish} name="basic" labelCol={{
                span: 8,
            }}
                wrapperCol={{
                    span: 18,
                }}>
                <div className='Settings-LeftArea'>
                    <div class="logo-container">
                        <img className='Settings-LeftArea-SchoolLogo' src={company && company[0].image} alt="" />
                        <div class='camera-icon-container'>
                            <Link><MdPhotoCamera className='Settings-LeftArea-SchoolLogo-Icon' />
                            </Link>
                        </div>
                    </div>
                    <div> <p className='Settings-LeftArea-SchoolName'>{company && company[0].name}</p>
                    </div>

                    <div className='Settings'>
                        <div className='Settings-LeftArea-InputBody'>
                            <p className='Settings-LeftArea-InputBody-Text'>Kurum Adı</p>
                            <Form.Item
                                name="schoolName"
                            >
                                <p className='Settings-LeftArea-InputBody-Input' >{company && company[0].name}</p>
                            </Form.Item>
                        </div>

                        <div className='Settings-LeftArea-InputBody'>
                            <p className='Settings-LeftArea-InputBody-Text'>E-Posta Adresi</p>
                            <Form.Item
                                name="emailAdress"
                            >
                                <p className='Settings-LeftArea-InputBody-Input' >{company && company[0].email}</p>
                            </Form.Item>
                        </div>
                    </div>

                    <div className='Settings'>
                        <div className='Settings-LeftArea-InputBody'>
                            <p className='Settings-LeftArea-InputBody-Text'>Telefon Numarası</p>
                            <Form.Item
                                name="phoneNumber"
                            >
                                <p className='Settings-LeftArea-InputBody-Input' >{company && company[0].phoneNumber}</p>
                            </Form.Item>
                        </div>

                        <div className='Settings-LeftArea-InputBody'>
                            <p className='Settings-LeftArea-InputBody-Text'>Şehir</p>
                            <Form.Item
                                name="city"
                            >
                                <p className='Settings-LeftArea-InputBody-Input' >{company && company[0].province}</p>
                            </Form.Item>
                        </div>
                    </div>

                    <div className='Settings'>
                        <div className='Settings-LeftArea-InputBody'>
                            <p className='Settings-LeftArea-InputBody-Text'>İlçe</p>
                            <Form.Item
                                name="district"
                            >
                                <p className='Settings-LeftArea-InputBody-Input' >{company && company[0].county}</p>
                            </Form.Item>
                        </div>

                        <div className='Settings-LeftArea-InputBody'>
                            <p className='Settings-LeftArea-InputBody-Text'>Seçili Paket</p>
                            <Form.Item
                                name="selectedPackage"
                            >
                                <p className='Settings-LeftArea-InputBody-Input' >{company && company[0].packageId}</p>
                            </Form.Item>
                        </div>
                    </div>

                    <div className='Settings'>
                        <div className='Settings-LeftArea-InputBody'>
                            <p className='Settings-LeftArea-InputBody-Text'>Öğrenci Sayısı</p>
                            <Form.Item
                                name="numberOfStudents"
                            >
                                <p className='Settings-LeftArea-InputBody-Input' >{company && company[0].studentCount}</p>
                            </Form.Item>
                        </div>

                        <div className='Settings-LeftArea-InputBody'>
                            <p className='Settings-LeftArea-InputBody-Text'>Öğretmen Sayısı</p>
                            <Form.Item
                                name="numberOfTeachers"
                            >
                                <p className='Settings-LeftArea-InputBody-Input' >{company && company[0].teacherCount}</p>
                            </Form.Item>
                        </div>
                    </div>
                    <div className='Settings'>
                        <div className='Settings-LeftArea-InputBody-Adress'>
                            <p className='Settings-LeftArea-InputBody-Text'>Adres</p>
                            <Form.Item
                                name="adress"
                            >
                                <p className='Settings-LeftArea-InputBody-Input' >{company && company[0].address}</p>
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>

            <div className='Settings-RightArea'>
                <div class="notificationlogo-container">
                    <IoNotifications className='Settings-RightArea-NotificationLogo' />
                    <div class='tick-icon-container'>
                        <TiTick className='Settings-RightArea-NotificationLogo-Icon' />
                    </div>
                </div>

                <div>
                    <p className='Settings-RightArea-NotificationLogo-Text'>Bildirim Gönder</p>
                    <p className='Settings-RightArea-NotificationLogo-Text-Explanation'>Öğrencilerinize Studia uygulaması üzerinden bildirim gönderin.</p>
                </div>

                <div className='Settings'>
                    <div className='Settings-RightArea-FormText-NotificationTitle'>
                        <Form.Item
                            name="notificationTitle"
                            label="Bildirim Başlığı"
                            rules={[
                                {
                                    required: true,
                                    message: 'Bildirim Başlığı Boş Bırakılamaz!',
                                },
                            ]}>
                            <Input type='text' className='Settings-RightArea-FormText-NotificationTitle-Input' />
                        </Form.Item>

                    </div>

                </div>
                <p className="Settings-RightArea-FormText-NotificationTitle-Explanation">Bildirim başlığı olarak gösterilir.</p>

                <div className='Settings'>
                    <div className='Settings-RightArea-FormText-NotificationTitle'>
                        <Form.Item
                            name="notificationContent"
                            label="Bildirim İçeriği"
                            rules={
                                [
                                    {
                                        required: true,
                                        message: 'Bildirim Başlığı Boş Bırakılamaz!',
                                    },
                                ]}>
                            <Input.TextArea className='Settings-RightArea-FormText-NotificationContent-Input' autoSize={{
                                minRows: 2,
                                maxRows: 2,
                            }} />
                        </Form.Item>
                    </div>
                </div>
                <p className="Settings-RightArea-FormText-NotificationContent-Explanation">Bildirim metni olarak gösterilir.</p>


                <div className='Settings'>
                    <div className='Settings-RightArea-FormText-NotificationTitle'>
                        <Form.Item
                            name="notificationPicture"
                            label="Bildirim Resmi"
                            rules={
                                [
                                    {
                                        required: false,
                                        message: '',
                                    },
                                ]}>
                            <Input type='text' className='Settings-RightArea-FormText-NotificationPicture' />
                            <Upload {...props}>
                                <Button icon={<UploadOutlined />} className='Settings-RightArea-FormText-NotificationPicture-Button' ></Button>
                            </Upload>

                        </Form.Item>
                    </div>
                </div>
                <p className="Settings-RightArea-FormText-NotificationPicture-Explanation">Bir URL veya resim ekleyin.</p>


                <div className='Settings'>
                    <div className='Settings-RightArea-FormText-NotificationTitle'>
                        <Form.Item
                            name="notificationDate"
                            label="Tarih"
                            rules={
                                [
                                    {
                                        required: true,
                                        message: 'Bildirim Tarihi Boş Bırakılamaz!',
                                    },
                                ]}>

                            <button className='Settings-RightArea-FormText-DateButton-Style'>Şimdi</button>
                            <span className='Settings-RightArea-FormText-Date-Or'> ya da </span>
                            <DatePicker format="DD/MM/YYYY" locale={locale} placeholder="" className='Settings-RightArea-FormText-Date' suffixIcon={<LuCalendarDays />} />
                        </Form.Item>
                    </div>
                </div>
                <p className="Settings-RightArea-FormText-Date-Explanation">Bildirim tarihi.</p>

                <div className='Settings'>
                    <div className='Settings-RightArea-FormText-NotificationTitle'>


                        <button className='Settings-RightArea-FormText-SentButton'>Gönder</button>
                    </div>
                </div>


            </div >

        </div >
    )
}


export default InstitutionalSettings