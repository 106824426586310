import React from 'react'
import "./InsHeader.scss";
import { IoMdNotificationsOutline, IoMdExit } from "react-icons/io";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { signoutUser } from '../../../../utils/firebase';
import { setlogout } from '../../../../stores/auth';


const InsHeader = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logoutHandler = async () => {
        await signoutUser();
        dispatch(setlogout());
        navigate("/auth");
    };


    const { pageName } = useSelector((state) => state.site);

    const HeaderDataMenu = [
        {
            icon: <IoMdNotificationsOutline className="InsHeader-Button" />,
            url: "/notification"
        },
        {
            icon: <IoMdExit className="InsHeader-Button" />,
            url: "/exit"
        },

        {
            url: "/upgradeplan",
            text: "Planı Yükselt"
        },


    ]


    return (
        <div className='InsHeader'>
            <div>
                <p className='InsHeader-Text'>{pageName}</p>
            </div>


            <div className="InsHeader-Right">
                <Link className="InsHeader-Right-Button" to="/notifications">
                    <IoMdNotificationsOutline className="InsHeader-Right-Button-icon" />
                </Link>
                <button className="InsHeader-Right-Button" onClick={logoutHandler}>
                    <IoMdExit className="InsHeader-Right-Button-icon" />
                </button>

                <Link className="InsHeader-Right-UpgradePlan" to="/institutional/plans">

                    <p>Plan Yükselt</p>

                </Link>
            </div>


        </div>

    )
}

export default InsHeader