import React, { useState } from 'react'
import Loading from '../../../shared/loading/loading';
import { useDispatch } from 'react-redux';
import { setPageName } from '../../../../stores/site';
import TheUnderConstruction from "../../../../assets/images/TheUnderConstruction.png";
import './InstitutionalTeachers.scss';

const InstitutionalTeachers = () => {
    const dispatch = useDispatch();
    dispatch(setPageName("Öğretmenler"))

    const [LoadingList, setLoadingList] = useState(true);


    return (
        <div className='InsTeachers'>
            <img src={TheUnderConstruction} height="300px" width="300px" />
            {LoadingList ? <Loading small={true} /> : <Loading small={true} />}

        </div>
    )
}

export default InstitutionalTeachers