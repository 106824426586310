import React, { useEffect } from "react";
import { Link, useOutletContext } from "react-router-dom";
import Service from "../../../../Service";
import './teachers.scss';
import { GetWord } from "../../../../utils";
import { Checkbox, Table } from "antd";
import * as AiIcons from "react-icons/ai";



const Teachers = () => {
  const [pageName, setPageName] = useOutletContext();
  useEffect(() => {
    asd();
  }, []);
  const asd = () => {
    const servis = new Service();
    servis.visa.getMyProfile().then((res) => {
      console.log(res);
    });
  };
  setPageName(GetWord("Eğitmenler"));

  const ListColumns = [
    {
      title: 'ID',
      dataIndex: 'ID',

    },
    {
      title: 'Kayıt Tarihi',
      dataIndex: 'dateOfRegistration',
    },
    {
      title: 'İsim',
      dataIndex: 'name',
    },
    {
      title: 'E-Posta Adresi',
      dataIndex: 'eMail',
    },
    {
      title: 'Hesap Bakiyesi',
      dataIndex: 'rightToQuestion',
    },
    {
      title: 'İşlem',
      dataIndex: 'button',
    },
  ];

  const ListData = [
    {
      key: '1',
      ID: '#001234',
      dateOfRegistration: '12.12.2022 - 12:58',
      name: 'Mali KARA',
      eMail: 'deneme@deneme.com',
      rightToQuestion: '205',
      button: <button className="Students-DetailButton" href="/teachers" style={{ cursor: 'pointer' }}><span className="Students-DetailButton-Text">Detay</span></button>,
    },
    {
      key: '1',
      ID: '#001234',
      dateOfRegistration: '12.12.2022 - 12:58',
      name: 'Mali KARA',
      eMail: 'deneme@deneme.com',
      rightToQuestion: '205',
      button: <button className="Students-DetailButton" onClick={<Teachers />} style={{ cursor: 'pointer' }}><span className="Students-DetailButton-Text">Detay</span></button>,
    },
    {
      key: '1',
      ID: '#001234',
      dateOfRegistration: '12.12.2022 - 12:58',
      name: 'Mali KARA',
      eMail: 'deneme@deneme.com',
      rightToQuestion: '205',
      button: <button className="Students-DetailButton" onClick={<Teachers />} style={{ cursor: 'pointer' }}><span className="Students-DetailButton-Text">Detay</span></button>,
    },
    {
      key: '1',
      ID: '#001234',
      dateOfRegistration: '12.12.2022 - 12:58',
      name: 'Mali KARA',
      eMail: 'deneme@deneme.com',
      rightToQuestion: '205',
      button: <button className="Students-DetailButton" onClick={<Teachers />} style={{ cursor: 'pointer' }}><span className="Students-DetailButton-Text">Detay</span></button>,
    },
    {
      key: '1',
      ID: '#001234',
      dateOfRegistration: '12.12.2022 - 12:58',
      name: 'Mali KARA',
      eMail: 'deneme@deneme.com',
      rightToQuestion: '205',
      button: <button className="Students-DetailButton" onClick={<Teachers />} style={{ cursor: 'pointer' }}><span className="Students-DetailButton-Text">Detay</span></button>,
    },
    {
      key: '1',
      ID: '#001234',
      dateOfRegistration: '12.12.2022 - 12:58',
      name: 'Mali KARA',
      eMail: 'deneme@deneme.com',
      rightToQuestion: '205',
      button: <button className="Students-DetailButton" onClick={<Teachers />} style={{ cursor: 'pointer' }}><span className="Students-DetailButton-Text">Detay</span></button>,
    },
    {
      key: '1',
      ID: '#001234',
      dateOfRegistration: '12.12.2022 - 12:58',
      name: 'Mali KARA',
      eMail: 'deneme@deneme.com',
      rightToQuestion: '205',
      button: <button className="Students-DetailButton" onClick={<Teachers />} style={{ cursor: 'pointer' }}><span className="Students-DetailButton-Text">Detay</span></button>,
    },
    {
      key: '1',
      ID: '#001234',
      dateOfRegistration: '12.12.2022 - 12:58',
      name: 'Mali KARA',
      eMail: 'deneme@deneme.com',
      rightToQuestion: '205',
      button: <button className="Students-DetailButton" onClick={<Teachers />} style={{ cursor: 'pointer' }}><span className="Students-DetailButton-Text">Detay</span></button>,
    },
    {
      key: '1',
      ID: '#001234',
      dateOfRegistration: '12.12.2022 - 12:58',
      name: 'Mali KARA',
      eMail: 'deneme@deneme.com',
      rightToQuestion: '205',
      button: <button className="Students-DetailButton" onClick={<Teachers />} style={{ cursor: 'pointer' }}><span className="Students-DetailButton-Text">Detay</span></button>,
    },
    {
      key: '1',
      ID: '#001234',
      dateOfRegistration: '12.12.2022 - 12:58',
      name: 'Mali KARA',
      eMail: 'deneme@deneme.com',
      rightToQuestion: '205',
      button: <button className="Students-DetailButton" onClick={<Teachers />} style={{ cursor: 'pointer' }}><span className="Students-DetailButton-Text">Detay</span></button>,
    },
    {
      key: '1',
      ID: '#001234',
      dateOfRegistration: '12.12.2022 - 12:58',
      name: 'Mali KARA',
      eMail: 'deneme@deneme.com',
      rightToQuestion: '205',
      button: <button className="Students-DetailButton" onClick={<Teachers />} style={{ cursor: 'pointer' }}><span className="Students-DetailButton-Text">Detay</span></button>,
    },
    {
      key: '1',
      ID: '#001234',
      dateOfRegistration: '12.12.2022 - 12:58',
      name: 'Mali KARA',
      eMail: 'deneme@deneme.com',
      rightToQuestion: '205',
      button: <button className="Students-DetailButton" onClick={<Teachers />} style={{ cursor: 'pointer' }}><span className="Students-DetailButton-Text">Detay</span></button>,
    },
    {
      key: '1',
      ID: '#001234',
      dateOfRegistration: '12.12.2022 - 12:58',
      name: 'Mali KARA',
      eMail: 'deneme@deneme.com',
      rightToQuestion: '205',
      button: <button className="Students-DetailButton" onClick={<Teachers />} style={{ cursor: 'pointer' }}><span className="Students-DetailButton-Text">Detay</span></button>,
    },
    {
      key: '1',
      ID: '#001234',
      dateOfRegistration: '12.12.2022 - 12:58',
      name: 'Mali KARA',
      eMail: 'deneme@deneme.com',
      rightToQuestion: '205',
      button: <button className="Students-DetailButton" onClick={<Teachers />} style={{ cursor: 'pointer' }}><span className="Students-DetailButton-Text">Detay</span></button>,
    },
    {
      key: '1',
      ID: '#001234',
      dateOfRegistration: '12.12.2022 - 12:58',
      name: 'Mali KARA',
      eMail: 'deneme@deneme.com',
      rightToQuestion: '205',
      button: <button className="Students-DetailButton" onClick={<Teachers />} style={{ cursor: 'pointer' }}><span className="Students-DetailButton-Text">Detay</span></button>,
    },
    {
      key: '1',
      ID: '#001234',
      dateOfRegistration: '12.12.2022 - 12:58',
      name: 'Mali KARA',
      eMail: 'deneme@deneme.com',
      rightToQuestion: '205',
      button: <button className="Students-DetailButton" onClick={<Teachers />} style={{ cursor: 'pointer' }}><span className="Students-DetailButton-Text">Detay</span></button>,
    },


  ];


  return (
    <div>
      <div className="Teachers-Application">
        <div className="Teachers-Input">
          <input
            type="search"
            className="Teachers-Input-Style"
            placeholder={GetWord("Ara...")}
          />
          <span className="Teachers-Input-SearchLogo">
            <AiIcons.AiOutlineSearch className="Teachers-Input-SearchLogo-Style" />
          </span>
        </div>
        <Link to="/teachers/applications">
          <button className="Teachers-Application-Button" style={{ cursor: 'pointer' }} href="/teachers/applications">
            <span className="Teachers-Application-Button-Text">Başvurular</span><span className="Teachers-Application-Button-Text-Number">5</span>
          </button>
        </Link>

      </div>
      <div>

        <Table
          rowSelection={{
            type: Checkbox,
          }}
          scroll={{ x: 'fit-content' }}
          columns={ListColumns}
          dataSource={ListData}
        />
      </div>
    </div>
  );
};

export default Teachers;
