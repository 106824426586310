import React, { useEffect, useState } from "react";
import './dashboard.scss'
import LeftAreaCard from "./LeftAreaCard/LeftAreaCard.jsx";
import { useDispatch } from "react-redux";
import { setPageName } from "../../../../stores/site.js";
import { Pie } from '@ant-design/plots';
import { Table } from "antd";
import { Link } from "react-router-dom";
import { DualAxes } from '@ant-design/plots';





const Dashboard = () => {
  const dispatch = useDispatch();
  dispatch(setPageName("Dashboard"))


  const CardData = [
    {

      icon: <img src={process.env.PUBLIC_URL + "/Assets/images/TotalTeachersIcon.svg"} />,
      number: "1852",
      bigTitle: "Toplam Eğitmen",
      explanation: "+150 bugün",
    },
    {
      icon: <img src={process.env.PUBLIC_URL + "/Assets/images/TotalStudentsIcon.svg"} />,
      number: "125485",
      bigTitle: "Toplam Öğrenci",
      explanation: "+1420 bugün",
    },
    {
      icon: < img src={process.env.PUBLIC_URL + "/Assets/images/TotalQuestionsIcon.svg"} />,
      number: "1852",
      bigTitle: "Bekleyen Soru",
      explanation: "",

    },
    {
      icon: < img src={process.env.PUBLIC_URL + "/Assets/images/TotalQuestionsIcon.svg"} />,
      number: "84125",
      bigTitle: "Toplam Soru",
      explanation: "",

    },
  ]


  const activeStudents = 81;
  const inactiveStudents = 100 - activeStudents;

  const ActiveStudentsData = [
    {
      type: 'Aktif Öğrenciler',
      value: activeStudents,
    },
    {
      type: '',
      value: inactiveStudents,
    },
  ];

  const ActiveStudentsConfig = {
    appendPadding: 10,
    data: ActiveStudentsData,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.5,
    label: {
      type: 'inner',
      offset: '-50%',
      style: {
        textAlign: 'center',
        fontSize: 1,
        legend: false,
      },

    },
    tooltip: false,
    legend: false,
    statistic: {
      title: true,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: '',
          offsetY: 14,
          fontSize: 15,

        },
        content: activeStudents + '%',
      },
    },
    color: ['#447ff4', '#f6eeff',],
  };

  const InactiveStudentsData = [
    {
      type: 'İnaktif Öğrenciler',
      value: inactiveStudents,
    },
    {
      type: '',
      value: activeStudents,
    },
  ];

  const InactiveStudentsConfig = {
    appendPadding: 10,
    data: InactiveStudentsData,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.5,
    label: {
      type: 'inner',
      offset: '-50%',
      style: {
        textAlign: 'center',
        fontSize: 1,
        legend: false,
      },

    },
    tooltip: false,
    legend: false,
    statistic: {
      title: true,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: '',
          offsetY: 14,
          fontSize: 15,

        },
        content: inactiveStudents + '%',
      },
    },
    color: ['#eff629', '#f6eeff',],
  };

  const activeTeachers = 62;
  const inactiveTeachers = 100 - activeTeachers;

  const ActiveTeachersData = [
    {
      type: 'Aktif Eğitmenler',
      value: activeTeachers,
    },
    {
      type: '',
      value: inactiveTeachers,
    },
  ];

  const ActiveTeachersConfig = {
    appendPadding: 10,
    data: ActiveTeachersData,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.5,
    label: {
      type: 'inner',
      offset: '-50%',
      style: {
        textAlign: 'center',
        fontSize: 1,
        legend: false,
      },

    },
    tooltip: false,
    legend: false,
    statistic: {
      title: true,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: '',
          offsetY: 14,
          fontSize: 15,

        },
        content: activeTeachers + '%',
      },
    },
    color: ['#fe69b5', '#f6eeff',],
  };


  const PaymentRequestData = [
    {
      icon:
        <img src={process.env.PUBLIC_URL + "/Assets/images/paymentpacketicon.svg"} alt="" />,
      studentName: <div className="RecentlySoldPackages">
        Mehmet Ali KARA
      </div>,
      amount: '100₺',
    },
    {
      icon:
        <img src={process.env.PUBLIC_URL + "/Assets/images/paymentpacketicon.svg"} alt="" />,
      studentName: <div className="RecentlySoldPackages">
        Mehmet Ali KARA
      </div>,
      amount: '100₺',
    },
    {
      icon:
        <img src={process.env.PUBLIC_URL + "/Assets/images/paymentpacketicon.svg"} alt="" />,
      studentName: <div className="RecentlySoldPackages">
        Mehmet Ali KARA
      </div>,
      amount: '100₺',
    },
    {
      icon:
        <img src={process.env.PUBLIC_URL + "/Assets/images/paymentpacketicon.svg"} alt="" />,
      studentName: <div className="RecentlySoldPackages">
        Mehmet Ali KARA
      </div>,
      amount: '100₺',
    },
    {
      icon:
        <img src={process.env.PUBLIC_URL + "/Assets/images/paymentpacketicon.svg"} alt="" />,
      studentName: <div className="RecentlySoldPackages">
        Mehmet Ali KARA
      </div>,
      amount: '100₺',
    },
    {
      icon:
        <img src={process.env.PUBLIC_URL + "/Assets/images/paymentpacketicon.svg"} alt="" />,
      studentName: <div className="RecentlySoldPackages">
        Mehmet Ali KARA
      </div>,
      amount: '100₺',
    },
    {
      icon:
        <img src={process.env.PUBLIC_URL + "/Assets/images/paymentpacketicon.svg"} alt="" />,
      studentName: <div className="RecentlySoldPackages">
        Mehmet Ali KARA
      </div>,
      amount: '100₺',
    },
    {
      icon:
        <img src={process.env.PUBLIC_URL + "/Assets/images/paymentpacketicon.svg"} alt="" />,
      studentName: <div className="RecentlySoldPackages">
        Mehmet Ali KARA
      </div>,
      amount: '100₺',
    },
    {
      icon:
        <img src={process.env.PUBLIC_URL + "/Assets/images/paymentpacketicon.svg"} alt="" />,
      studentName: <div className="RecentlySoldPackages">
        Mehmet Ali KARA
      </div>,
      amount: '100₺',
    },
    {
      icon:
        <img src={process.env.PUBLIC_URL + "/Assets/images/paymentpacketicon.svg"} alt="" />,
      studentName: <div className="RecentlySoldPackages">
        Mehmet Ali KARA
      </div>,
      amount: '100₺',
    },
  ];
  const PaymentRequestLimitedData = PaymentRequestData.slice(0, 8);
  const PaymentRequestColumns = [
    {
      title: '',
      dataIndex: 'icon',
    },
    {
      title: '',
      dataIndex: 'studentName',
    },
    {
      title: '',
      dataIndex: 'explanation',
    },
    {
      title: '',
      dataIndex: 'amount',
    },
  ];

  const SellPacketData = [
    {
      icon:
        <img src={process.env.PUBLIC_URL + "/Assets/images/paymentpacketicon.svg"} alt="" />,
      studentName: <div className="RecentlySoldPackages">
        Mehmet Ali KARA
        <p className="Dashboard-RightArea-Lists-RecentlySoldPackages-Title-Explanation-Small">Haftalık 100 Soru</p>
      </div>,
      amount: '100₺',
    },
    {
      icon:
        <img src={process.env.PUBLIC_URL + "/Assets/images/paymentpacketicon.svg"} alt="" />,
      studentName: <div className="RecentlySoldPackages">
        Mehmet Ali KARA
        <p className="Dashboard-RightArea-Lists-RecentlySoldPackages-Title-Explanation-Small">Haftalık 100 Soru</p>
      </div>,
      amount: '100₺',
    },
    {
      icon:
        <img src={process.env.PUBLIC_URL + "/Assets/images/paymentpacketicon.svg"} alt="" />,
      studentName: <div className="RecentlySoldPackages">
        Mehmet Ali KARA
        <p className="Dashboard-RightArea-Lists-RecentlySoldPackages-Title-Explanation-Small">Haftalık 100 Soru</p>
      </div>,
      amount: '100₺',
    },
    {
      icon:
        <img src={process.env.PUBLIC_URL + "/Assets/images/paymentpacketicon.svg"} alt="" />,
      studentName: <div className="RecentlySoldPackages">
        Mehmet Ali KARA
        <p className="Dashboard-RightArea-Lists-RecentlySoldPackages-Title-Explanation-Small">Haftalık 100 Soru</p>
      </div>,
      amount: '100₺',
    },
    {
      icon:
        <img src={process.env.PUBLIC_URL + "/Assets/images/paymentpacketicon.svg"} alt="" />,
      studentName: <div className="RecentlySoldPackages">
        Mehmet Ali KARA
        <p className="Dashboard-RightArea-Lists-RecentlySoldPackages-Title-Explanation-Small">Haftalık 100 Soru</p>
      </div>,
      amount: '100₺',
    },
    {
      icon:
        <img src={process.env.PUBLIC_URL + "/Assets/images/paymentpacketicon.svg"} alt="" />,
      studentName: <div className="RecentlySoldPackages">
        Mehmet Ali KARA
        <p className="Dashboard-RightArea-Lists-RecentlySoldPackages-Title-Explanation-Small">Haftalık 100 Soru</p>
      </div>,
      amount: '100₺',
    },
    {
      icon:
        <img src={process.env.PUBLIC_URL + "/Assets/images/paymentpacketicon.svg"} alt="" />,
      studentName: <div className="RecentlySoldPackages">
        Mehmet Ali KARA
        <p className="Dashboard-RightArea-Lists-RecentlySoldPackages-Title-Explanation-Small">Haftalık 100 Soru</p>
      </div>,
      amount: '100₺',
    },
    {
      icon:
        <img src={process.env.PUBLIC_URL + "/Assets/images/paymentpacketicon.svg"} alt="" />,
      studentName: <div className="RecentlySoldPackages">
        Mehmet Ali KARA
        <p className="Dashboard-RightArea-Lists-RecentlySoldPackages-Title-Explanation-Small">Haftalık 100 Soru</p>
      </div>,
      amount: '100₺',
    },
    {
      icon:
        <img src={process.env.PUBLIC_URL + "/Assets/images/paymentpacketicon.svg"} alt="" />,
      studentName: <div className="RecentlySoldPackages">
        Mehmet Ali KARA
        <p className="Dashboard-RightArea-Lists-RecentlySoldPackages-Title-Explanation-Small">Haftalık 100 Soru</p>
      </div>,
      amount: '100₺',
    },
    {
      icon:
        <img src={process.env.PUBLIC_URL + "/Assets/images/paymentpacketicon.svg"} alt="" />,
      studentName: <div className="RecentlySoldPackages">
        Mehmet Ali KARA
        <p className="Dashboard-RightArea-Lists-RecentlySoldPackages-Title-Explanation-Small">Haftalık 100 Soru</p>
      </div>,
      amount: '100₺',
    },
  ];
  const SellPacketLimitedData = SellPacketData.slice(0, 8);
  const SellPacketColumns = [
    {
      title: '',
      dataIndex: 'icon',
    },
    {
      title: '',
      dataIndex: 'studentName',
    },
    {
      title: '',
      dataIndex: 'explanation',
    },
    {
      title: '',
      dataIndex: 'amount',
    },

  ];

  const StudentTeacherGraphicData = [
    {
      day: 'Pazartesi',
      student: 3,
      teacher: 10,
    },
    {
      day: 'Salı',
      student: 4,
      teacher: 4,
    },
    {
      day: 'Çarşamba',
      student: 3.5,
      teacher: 5,
    },
    {
      day: 'Perşembe',
      student: 5,
      teacher: 5,
    },
    {
      day: 'Cuma',
      student: 4.9,
      teacher: 4.9,
    },
    {
      day: 'Cumartesi',
      student: 6,
      teacher: 35,
    },
    {
      day: 'Pazar',
      student: 7,
      teacher: 7,
    },

  ];
  const StudentTeacherGraphicConfig = {
    data: [StudentTeacherGraphicData, StudentTeacherGraphicData],
    xField: 'day',
    yField: ['student', 'teacher'],
    geometryOptions: [
      {
        geometry: 'line',
        smooth: true,
        color: '#447ff4',
        label: null,
        point: {
          shape: 'circle',
          size: 0,
          style: {
            opacity: 1,
            stroke: '#447ff4',
            fill: '#fff',
          },
        },
        lineStyle: {
          lineWidth: 10,
          opacity: 1,
        },
      },
      {
        geometry: 'line',
        smooth: true,
        color: '#fe69b5',
        lineStyle: {
          lineWidth: 10,
          opacity: 1,
        },
        label: null,
        point: {
          shape: 'circle',
          size: 0,
          style: {
            opacity: 1,
            stroke: '#fe69b5',
            fill: '#fff',
          },
        },
      },
    ],
    legend: false,
    meta: {
      student: {
        alias: 'Öğrenciler',
      },
      teacher: {
        alias: 'Öğretmenler',
      },
    },
  };


  const QuestionsData = [
    {
      day: 'Pazartesi',
      question: 3,
    },
    {
      day: 'Salı',
      question: 4,
    },
    {
      day: 'Çarşamba',
      question: 5,
    },
    {
      day: 'Perşembe',
      question: 20,
    },
    {
      day: 'Cuma',
      question: 15,
    },
    {
      day: 'Cumartesi',
      question: 25,
    },
    {
      day: 'Pazar',
      question: 1,
    },

  ];
  const QuestionsConfig = {
    data: [QuestionsData, QuestionsData],
    xField: 'day',
    yField: ['question'],
    geometryOptions: [
      {
        geometry: 'line',
        smooth: true,
        color: '#f4f444',
        label: null,
        point: {
          shape: 'circle',
          size: 0,
          style: {
            opacity: 1,
            stroke: '#447ff4',
            fill: '#fff',
          },

        },
        lineStyle: {
          lineWidth: 10,
          opacity: 1,
        },

      },
      {
        geometry: 'line',
        lineStyle: {
          lineWidth: 0,
          opacity: 0,
        },

      },
    ],
    legend: false,
    meta: {
      question: {
        alias: 'Günlük Soru Dağılımı',
      },
    },
  };




  return (
    <div className="Dashboard">
      <div className="Dashboard-LeftArea">
        <div className="Dashboard-LeftArea-DashboardCard">
          <div className="Dashboard-LeftArea-DashboardCard-Style">

            {
              CardData.map((item, i) => {

                return <LeftAreaCard item={item} key={i} index={i} />
              }
              )
            }

          </div>
        </div>
        <div className="Dashboard-LeftArea-ActiveUsers">
          <p className="Dashboard-LeftArea-ActiveUsers-Title">
            Aktif Kullanıcılar
          </p>
          <div className="Dashboard-LeftArea-ActiveUsers-GraficArea">
            <div className="Dashboard-LeftArea-ActiveUsers-GraficArea-ActiveStudents-Grafic">
              <Pie {...ActiveStudentsConfig} />
              <p className="Dashboard-LeftArea-ActiveUsers-GraficArea-ActiveStudents-Explanation">
                Aktif Öğrenciler
              </p>
            </div>

            <div className="Dashboard-LeftArea-ActiveUsers-GraficArea-InactiveStudents-Grafic">
              <Pie {...InactiveStudentsConfig} />
              <p className="Dashboard-LeftArea-ActiveUsers-GraficArea-InactiveStudents-Explanation">
                İnaktif Öğrenciler
              </p>
            </div>

            <div className="Dashboard-LeftArea-ActiveUsers-GraficArea-ActiveTeachers-Grafic">
              <Pie {...ActiveTeachersConfig} />
              <p className="Dashboard-LeftArea-ActiveUsers-GraficArea-ActiveTeachers-Explanation">
                Aktif Eğitmenler
              </p>
            </div>

          </div>
        </div>
        <div className="Dashboard-LeftArea-QuestionsAsked">
          <p className="Dashboard-LeftArea-QuestionsAsked-Title">
            Sorulan Sorular</p>
          <div className="Dashboard-LeftArea-QuestionsAsked-Information" >
            <div className="Dashboard-LeftArea-QuestionsAsked-Information-Student" >
              <img src={process.env.PUBLIC_URL + "/Assets/images/UpArrowIcon.svg"} alt="" className="Dashboard-LeftArea-QuestionsAsked-Information-Student-Icon" />
              <div className="Dashboard-LeftArea-QuestionsAsked-Information-Student-Text">

                <div className="Dashboard-LeftArea-QuestionsAsked-Information-Student-Text-Title">Sorular</div>
                <div className="Dashboard-LeftArea-QuestionsAsked-Information-Student-Text-Number">125.485</div>
              </div>
            </div>
          </div>

          <DualAxes {...QuestionsConfig} />

        </div>



      </div>

      <div className="Dashboard-RightArea">
        <div className="Dashboard-RightArea-Graphic">
          <div className="Dashboard-RightArea-Graphic-Information" >
            <div className="Dashboard-RightArea-Graphic-Information-Student" >
              <img src={process.env.PUBLIC_URL + "/Assets/images/UpArrowIcon.svg"} alt="" className="Dashboard-RightArea-Graphic-Information-Student-Icon" />
              <div className="Dashboard-RightArea-Graphic-Information-Student-Text">

                <div className="Dashboard-RightArea-Graphic-Information-Student-Text-Title">Öğrenci</div>
                <div className="Dashboard-RightArea-Graphic-Information-Student-Text-Number">125.485</div>
              </div>
            </div>


            <div className="Dashboard-RightArea-Graphic-Information-Teacher" >
              <img src={process.env.PUBLIC_URL + "/Assets/images/UpArrowIcon.svg"} alt="" className="Dashboard-RightArea-Graphic-Information-Teacher-Icon" />
              <div className="Dashboard-RightArea-Graphic-Information-Teacher-Text">

                <div className="Dashboard-RightArea-Graphic-Information-Teacher-Text-Title">Eğitmen</div>
                <div className="Dashboard-RightArea-Graphic-Information-Teacher-Text-Number">1.852</div>
              </div>
            </div>

          </div>
          <DualAxes {...StudentTeacherGraphicConfig} />


        </div>
        <div className="Dashboard-RightArea-Lists">

          <div className="Dashboard-RightArea-Lists-PaymentRequests">
            <div className="Dashboard-RightArea-Lists-PaymentRequests-Title">
              <p className="Dashboard-RightArea-Lists-PaymentRequests-Title-Text">
                Ödeme Talepleri
              </p>
              <p className="Dashboard-RightArea-Lists-PaymentRequests-Title-Explanation">
                24 saattki ödeme talepleri
              </p>
            </div>
            <Table
              columns={PaymentRequestColumns}
              dataSource={PaymentRequestLimitedData}
              pagination={false} //
            />
            <div className="Dashboard-RightArea-Lists-PaymentRequests-MoreArea">
              <Link to="/PaymentRequests" className="Dashboard-RightArea-Lists-PaymentRequests-MoreArea-More" >
                <span className="Dashboard-RightArea-Lists-PaymentRequests-MoreArea-More-Text">250 tane daha</span>
              </Link>
            </div>

          </div>
          <div className="Dashboard-RightArea-Lists-RecentlySoldPackages">
            <div className="Dashboard-RightArea-Lists-RecentlySoldPackages-Title">
              <p className="Dashboard-RightArea-Lists-RecentlySoldPackages-Title-Text">
                Son Satılan Paketler
              </p>
              <p className="Dashboard-RightArea-Lists-RecentlySoldPackages-Title-Explanation">
                24 saatte satılan paketler
              </p>
            </div>
            <Table
              columns={SellPacketColumns}
              dataSource={SellPacketLimitedData}
              pagination={false} //
            />
            <div className="Dashboard-RightArea-Lists-RecentlySoldPackages-MoreArea">
              <Link to="/sellpacket" className="Dashboard-RightArea-Lists-RecentlySoldPackages-MoreArea-More" >
                <span className="Dashboard-RightArea-Lists-RecentlySoldPackages-MoreArea-More-Text">250 tane daha</span>
              </Link>
            </div>

          </div>
        </div>
      </div>
    </div>

  );
};

export default Dashboard;
