import React, { useEffect, useState } from 'react'
import "./InstitutionalStats.scss"
import { useDispatch } from 'react-redux';
import { setPageName } from '../../../../stores/site';
import InsStudentsList from '../../components/StudentsList/InsStudentsList';
import { Column, Pie } from '@ant-design/plots';
import { Select } from 'antd';
import { Option } from 'antd/es/mentions';
import Service from '../../../../Service';
import { GetWord } from '../../../../utils';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Loading from '../../../shared/loading/loading';

const InstitutionalStats = () => {

    const dispatch = useDispatch();
    dispatch(setPageName("İstatistikler"))

    const [Organisation, setOrganisation] = useState();
    const [LastWeekDailyData, setLastWeekDailyData] = useState();
    const [QuestionMonthData, setQuestionMonthData] = useState();
    const [QuestionTopicData, setQuestionTopicData] = useState();
    const [LessonData, setLessonData] = useState();
    const [ClassList, setClassList] = useState();
    const [SelectedClassId, setSelectedClassId] = useState();
    const [SelectedMonth, setSelectedMonth] = useState(1);
    const [GpLoading, setGpLoading] = useState(true);
    const [GpLoading1, setGpLoading1] = useState(true);
    const [GpLoading2, setGpLoading2] = useState(true);
    const [GpLoading3, setGpLoading3] = useState(true);
    const [LoadingList, setLoadingList] = useState(true);
    const [noQuestionUsers, setnoQuestionUsers] = useState();
    const [StudentList, setStudentList] = useState()

    const { organisationId } = useParams();

    // const getOrganisation = () => {
    //     const service = new Service();
    //     service.Organisations.organisationId(1).then((res) => {
    //         setOrganisation(res);
    //     })
    // }



    const getnoQuestionUsers = () => {
        const service = new Service();
        service.Organisations.NoQuestionUsers(1).then((res) => {
            setnoQuestionUsers(res.map((item) => ({ user: item })));
            setLoadingList(false)
        })
    }

    const getLastWeekDailyData = () => {
        const service = new Service();
        service.Organisations.QuestionLastWeekStatics(1).then((res) => {
            setLastWeekDailyData(Object.entries(res).map(([key, value]) => ({ value: value, type: GetWord(key) })))
            setGpLoading(false);
        })
    }

    const getQuestionMonthData = (startDate, endDate) => {
        const service = new Service();
        service.Organisations.QuestionMonthStatics(1, startDate, endDate).then((res) => {
            setQuestionMonthData(Object.entries(res).map(([key, value]) => ({ value: value, type: GetWord(key) })).reverse())
            setGpLoading3(false)
        })
    }

    const getLessonData = (startDate, endDate) => {
        const service = new Service();
        service.Organisations.QuestionClassStatics(1, startDate, endDate).then((res) => {
            setLessonData(Object.entries(res).map(([key, value]) => ({ value: value, type: key })))
            setGpLoading1(false)
        })
    }


    const getQuestionTopicData = (classId, startDate, endDate) => {
        const service = new Service();
        service.Organisations.QuestionTopicStatics(1, classId, startDate, endDate).then((res) => {
            setQuestionTopicData(Object.entries(res).map(([key, value]) => ({ value: value, type: key })))
            setGpLoading2(false)
        })
    }

    const getClassesList = () => {
        const service = new Service();
        service.Classes.getClass().then((res) => {
            setClassList(res);
            if (res && res.length > 0) {
                setSelectedClassId(res[0].id)
            }
        })
    }

    const getStudent = () => {
        const service = new Service();
        service.Student.students().then((res) => {
            setStudentList(res.filter(item => !item.isActivation));
        })
    }


    const LessonSelected = (value) => {
        setGpLoading1(true)
        getLessonData(moment().add(-parseInt(value), "M").format("YYYY-MM-DD"), moment().add(+1, "days").format("YYYY-MM-DD"));
    }

    const TopicMonthtSelected = (value) => {
        setGpLoading2(true)
        setSelectedMonth(parseInt(value))
    }

    const TopicLessonSelected = (value) => {
        setGpLoading2(true)
        setSelectedClassId(parseInt(value))
    }

    useEffect(() => {
        getQuestionTopicData(SelectedClassId, moment().add(-SelectedMonth, "M").format("YYYY-MM-DD"), moment().add(+1, "days").format("YYYY-MM-DD"));
    }, [SelectedClassId, SelectedMonth])




    useEffect(() => {
        getLastWeekDailyData();
        getQuestionMonthData(moment().add(-1, "y").format("YYYY-MM-DD"), moment().add(+1, "M").format("YYYY-MM-DD"));
        getLessonData(moment().add(-1, "M").format("YYYY-MM-DD"), moment().add(+1, "days").format("YYYY-MM-DD"));
        getClassesList();
        getQuestionTopicData(SelectedClassId, moment().add(-1, "y").format("YYYY-MM-DD"), moment().add(+1, "M").format("YYYY-MM-DD"));
        getnoQuestionUsers();
        getStudent()
    }, [])




    const DailyConfig = {
        data: LastWeekDailyData,
        xField: 'type',
        yField: 'value',
        label: {
            // 可手动配置 label 数据标签位置
            position: 'middle',
            // 'top', 'bottom', 'middle',
            // 配置样式
            style: {
                fill: '#FFFFFF',
                opacity: 0.6,
            },
        },
        xAxis: {
            label: {
                autoHide: true,
                autoRotate: false,
            },
        },
        meta: {
            type: {
                alias: '类别',
            },
            value: {
                alias: 'Günlük Soru Dağılımı',
            },
        },
    };


    const TotalQuestionsConfig = {
        data: QuestionMonthData,
        xField: 'type',
        yField: 'value',
        label: {
            // 可手动配置 label 数据标签位置
            position: 'middle',
            // 'top', 'bottom', 'middle',
            // 配置样式
            style: {
                fill: '#FFFFFF',
                opacity: 0.6,
            },
        },
        xAxis: {
            label: {
                autoHide: true,
                autoRotate: true,
            },
        },
        meta: {
            type: {
                alias: '类别',
            },
            value: {
                alias: 'Toplam Soru Dağılımı',
            },
        },
    };

    const MostAskedTopicsConfig = {
        appendPadding: 10,
        data: QuestionTopicData,
        angleField: 'value',
        colorField: 'type',
        radius: 1,
        innerRadius: 0.6,
        label: {
            type: 'inner',
            offset: '-50%',
            content: '{value}',
            style: {
                textAlign: 'center',
                fontSize: 14,
            },
        },
        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
        ],
        statistic: {
            title: false,
            content: {
                style: {
                    whiteSpace: 'pre-wrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                content: '',
            },
        },
    };

    const QuestionByLessonsConfig = {
        appendPadding: 10,
        data: LessonData,
        angleField: 'value',
        colorField: 'type',
        radius: 1,
        innerRadius: 0.6,
        label: {
            type: 'inner',
            offset: '-50%',
            content: '{value}',
            style: {
                textAlign: 'center',
                fontSize: 14,
            },
        },
        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
        ],
        statistic: {
            title: false,
            content: {
                style: {
                    whiteSpace: 'pre-wrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                content: '',
            },
        },
    };





    return (
        <div className='InsStats'>
            <div className='InsStats-FirstArea'>
                <div className='InsStats-FirstArea-Grafic-1'>
                    <div className="InsStats-FirstArea-Grafic-1-Select" >
                        <p className='InsStats-FirstArea-Grafic-1-Title'>Günlük Soru Dağılımı</p>
                        {/* <Select defaultValue="Option1" className='InsStats-FirstArea-Grafic-1-Select-Style'>
                            <Option value="Option1" >Son 1 hafta</Option>
                        </Select> */}
                    </div>
                    {GpLoading ? <Loading small={true} /> : <Column {...DailyConfig} />}
                </div>

                <div className="InsStats-FirstArea-Grafic-2">
                    <div className="InsStats-FirstArea-Grafic-2-Select">
                        <p className="InsStats-FirstArea-Grafic-2-Title">Derslere Göre Soru Dağılımları</p>
                        <Select defaultValue="1" className='InsStats-FirstArea-Grafic-2-Select-Style' onChange={LessonSelected}>
                            <Option value="1" >Son 1 ay</Option>
                            <Option value="3" >Son 3 ay</Option>
                            <Option value="6" >Son 6 ay</Option>
                            <Option value="12" >Son 1 yıl</Option>
                        </Select>
                    </div>
                    {GpLoading1 ? <Loading small={true} /> : <Pie {...QuestionByLessonsConfig} />}
                </div>
            </div>


            <div className='InsStats-SecondArea'>
                <div className='InsStats-SecondArea-Grafic-1'>
                    <p className='InsStats-SecondArea-Grafic-1-Title'>Toplam Soru Dağılımları</p>
                    {GpLoading3 ? <Loading small={true} /> : <Column {...TotalQuestionsConfig} />}
                </div>
                <div className="InsStats-SecondArea-Grafic-2">
                    <div className="InsStats-SecondArea-Grafic-2-Select">
                        <p className="InsStats-SecondArea-Grafic-2-Title">En Çok Soru Sorulan Konular</p>
                        <div className="InsStats-SecondArea-Grafic-2-Select">
                            <Select defaultValue={ClassList && ClassList[0].id} className='InsStats-SecondArea-Grafic-2-Select-Style' onChange={TopicLessonSelected}>
                                {
                                    ClassList && ClassList.map((item, value) => (<Option value={item.id} >{item.name}</Option>))
                                }
                            </Select>
                            <Select defaultValue="1" className='InsStats-SecondArea-Grafic-2-Select-Style' onChange={TopicMonthtSelected}>
                                <Option value="1" >Son 1 ay</Option>
                                <Option value="3" >Son 3 ay</Option>
                                <Option value="6" >Son 6 ay</Option>
                                <Option value="12" >Son 1 yıl</Option>
                            </Select>
                        </div>
                    </div>
                    {GpLoading2 ? <Loading small={true} /> : <Pie {...MostAskedTopicsConfig} />}
                </div>
            </div>




            <div className='InsStats-ThirdArea'>
                <div className='InsStats-ThirdArea-ListsBody'>
                    <div className="InsStats-ThirdArea-Select">
                        <span className='InsStats-ThirdArea-ListTitles'>En Çok Soru Soranlar</span>
                        <Select defaultValue="Option1" className='InsStats-ThirdArea-Select-Style'>
                            <Option value="Option1" >Son 1 ay</Option>
                        </Select>
                    </div>
                    <InsStudentsList isVisibleMail={true} isVisibUserStatus={true} isVisibleAction={true} isVisibleRemaningQuestions={true} />
                </div>

                <div className='InsStats-ThirdArea-ListsBody'>
                    <div className="InsStats-ThirdArea-Select">
                        <span className='InsStats-ThirdArea-ListTitles'> Üyeliğini Aktif Etmemiş Öğrenciler</span>
                    </div>
                    <InsStudentsList isVisibleMail={true} isVisibleNumberOfQuestions={true} isVisibleAction={true} isVisibleRemaningQuestions={true} data={StudentList} />
                </div>

                <div className='InsStats-ThirdArea-ListsBody'>
                    <div className="InsStats-ThirdArea-Select">
                        <span className='InsStats-ThirdArea-ListTitles'>Soru Sormamış Öğrenciler</span>
                    </div>
                    {LoadingList ? <Loading small={true} /> :
                        <InsStudentsList isVisibleMail={true} isVisibUserStatus={true} isVisibleAction={true} isVisibleRemaningQuestions={true} data={noQuestionUsers} />
                    }
                </div>

            </div>

        </div>
    )
}
export default InstitutionalStats