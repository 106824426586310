import React, { useEffect, useState } from 'react'
import "./InstitutionalStudents.scss"
import Search from 'antd/es/input/Search'
import { Link } from 'react-router-dom'
import { setPageName } from '../../../../stores/site'
import { useDispatch } from 'react-redux'
import InsStudentsList from '../../components/StudentsList/InsStudentsList'
import Service from '../../../../Service'


const InstitutionalStudents = () => {
    const [userList, setUserList] = useState();
    const dispatch = useDispatch();
    dispatch(setPageName("Öğrenciler"))

    const getUser = () => {
        const service = new Service();
        service.Student.students().then((res) => {
            console.log(res);
            setUserList(res);
        })
    }

    useEffect(() => {
        getUser();
    }, [])

    return (
        <div>
            <div className='InsStudents-Header'>
                <div className="InsStudens-Header-Search">
                    <Search className='InsStudents-Header-Search'
                        placeholder="Öğrenci Ara"
                        size='large'
                        bordered='none"'
                    />

                </div>
                <div>

                    <Link className="InsStudents-Header-AddStudent" to="/institutional/students/add" >

                        + Öğrenci Ekle

                    </Link>

                </div>


            </div>
            <div className='InsStudents-StudentList'>
                <InsStudentsList data={userList} />
            </div>
        </div>
    )
}

export default InstitutionalStudents