import { Space, Table } from 'antd';
import React, { useState } from 'react'
import './InsStudentsList.scss'

const InsStudentsList = ({ isVisibleMail, isVisibUserStatus, isVisibleNumberOfQuestions, isVisibleRemaningQuestions, isVisibleAction, data }) => {

    var columns = [

        {
            title: 'İsim',
            dataIndex: ["user", 'name'],
            key: 'name',
            sorter: {
                compare: (a, b) => a.user.name.localeCompare(b.user.name),
                sortOrder: 'ascend',
            },

            render: (_, record) => (
                <div className='StudentList-Name'>
                    <img className="StudentList-Image" src={record.user.image !== undefined || record.user.image !== "" ? record.user.image : "https://cdn-icons-png.flaticon.com/512/5850/5850276.png"} alt={record.user.name} />
                    {
                        record.user.name + " " + record.user.surname
                    }</div >
            ),
            className: "StudentList-Data",

        },
    ];

    if (!isVisibleMail) {
        columns.push(
            {
                title: 'E-Posta',
                dataIndex: ["user", 'email'],
                key: 'email',
                sorter: {
                    compare: (a, b) => a.user.email.localeCompare(b.user.email),
                    sortOrder: 'ascend',
                },
                className: "StudentList-Data",

            },
        )
    }

    if (!isVisibUserStatus) {
        columns.push(
            {
                title: 'Kullanıcı Durumu',
                dataIndex: 'isActivation',
                key: 'isActivation',
                sorter: {
                    compare: (a, b) => a.isActivation.localeCompare(b.isActivation),
                    sortOrder: 'ascend',
                },
                render: (text, record) => {
                    const color = record.isActivation ? 'green' : 'red';
                    return <span style={{ color }}>{record.isActivation ? "Aktifleştirilmiş" : "Aktifleştirilmemiş"}</span>;
                },
                className: "StudentList-Data",
            },
        )

    }


    if (!isVisibleNumberOfQuestions) {
        columns.push(
            {
                title: 'Sorduğu Soru Sayısı',
                dataIndex: ["user", 'points'],
                key: 'points',
                sorter: {
                    compare: (a, b) => a.points - b.points,
                },
                className: "StudentList-Data",
            },
        )
    }

    if (!isVisibleRemaningQuestions) {
        columns.push(
            {
                title: "Kalan Soru Hakkı",
                dataIndex: 'remaningQuestions',
                key: 'remaningQuestions',
                render: (_, record) => (
                    <p>{record.user.videoAnswerSolutionRight + record.user.answerSolutionRight}</p>
                ),
                sorter: {
                    compare: (a, b) => a.remaningQuestions - b.remaningQuestions,
                },
                className: "StudentList-Data",
            },
        )
    }

    if (!isVisibleAction) {
        columns.push(
            {
                title: 'İşlem',
                key: 'action',
                render: (_, record) => (
                    <Space size="middle">
                        <a href={"/institutional/students/details/" + record.userId} className='StudentList-detailButton'>Detay</a>

                    </Space>
                ),
                className: "StudentList-Data",

            },
        )
    }

    return (
        <div className='StudentList'> <Table columns={columns} dataSource={data} /></div>
    )
}

export default InsStudentsList