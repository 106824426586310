import React from "react";
import { useOutletContext } from "react-router-dom";
import './reportedfixed.scss';
import { GetWord } from "../../../../utils";
import { Table } from "antd";

const Reportedfixed = () => {
  const [pageName, setPageName] = useOutletContext();
  setPageName(GetWord("Raporlanan Çözümler"));

  const ListColumns = [
    {
      title: 'Adı Soyadı',
      dataIndex: 'studentName',
    },
    {
      title: 'Eğitmen Adı',
      dataIndex: 'teacherName',
    },
    {
      title: 'Soru Fotoğrafı',
      dataIndex: 'questionPhoto',
    },
    {
      title: 'Çözüm Fotoğrafı',
      dataIndex: 'solitionPhoto',
    },

    {
      title: 'Rapor Sebebi',
      dataIndex: 'reasonOfReport',
    },
    {
      title: 'Tarih',
      dataIndex: 'date',
    },
    {
      title: 'İşlem',
      dataIndex: 'button',
    },
  ];

  const ListData = [
    {
      key: '1',
      studentName: 'Mehmet Ali KARA',
      teacherName: 'Mehmet Ali KARA',
      questionPhoto: <img className="ReportedFixed-QuestionPhoto"
        src='https://lh3.googleusercontent.com/q-Ag6tqk1BGvUHvq6HxPEEnBh_n9BxWoSp3k0gDNyyYDc_YCwJdOQq7Pcs35yjA0CVlv2koGkYWsw5iJBPuFQxv3I250TU-syesKmoAE0-Fzckvd7-PCORyTDUZUlQUeuH3RVFkn=s0' alt="" />,
      solitionPhoto: <img className="ReportedFixed-QuestionPhoto"
        src='https://lh3.googleusercontent.com/q-Ag6tqk1BGvUHvq6HxPEEnBh_n9BxWoSp3k0gDNyyYDc_YCwJdOQq7Pcs35yjA0CVlv2koGkYWsw5iJBPuFQxv3I250TU-syesKmoAE0-Fzckvd7-PCORyTDUZUlQUeuH3RVFkn=s0' alt="" />,

      reasonOfReport: 'Kopya',
      date: '12.05.2021 12.54',
      button: <div className="ReportedFixed-Button">
        <div className="ReportedFixed-Button-SeeDelete" >
          <button className="ReportedFixed-Button-SeeDelete-SeeQuestion" onClick={<Reportedfixed />} style={{ cursor: 'pointer' }}><span className="ReportedFixed-Button-Text">Soruyu Gör</span> </button>
          <button className="ReportedFixed-Button-SeeDelete-Delete" onClick={<Reportedfixed />} style={{ cursor: 'pointer' }}><span className="ReportedFixed-Button-Text">Sil</span></button>
        </div>
        <div className="ReportedFixed-Button-PriceRefund" >
          <button className="ReportedFixed-Button-PriceRefund-FullPrice" onClick={<Reportedfixed />} style={{ cursor: 'pointer' }}><span className="ReportedFixed-Button-Text">Tam Ücret</span> </button>
          <button className="ReportedFixed-Button-PriceRefund-QuestionRightRefund" onClick={<Reportedfixed />} style={{ cursor: 'pointer' }}><span className="ReportedFixed-Button-Text">Soru Hakkı İade</span></button>
        </div>
      </div>,
    },

  ];



  return (


    <div>
      <div className="ReportedFixed-All">
        <div></div>

        <button className="ReportedFixed-All-Button" onClick={<Reportedfixed />} style={{ cursor: 'pointer' }}>
          <span className="ReportedFixed-All-Button-Text">Tümünü Gör</span>
        </button>


      </div>
      <div>

        <Table
          columns={ListColumns}
          dataSource={ListData}
        />
      </div>
    </div>


  );
};

export default Reportedfixed;
