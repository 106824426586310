import React from 'react'
import "./InstitutionalPlans.scss";
import { TiTick } from "react-icons/ti";


const InstitutionalPlansCard = ({ item, index }) => {
    return (
        <div className='PlansCard'>
            <div className={"PlansCard-Package-" + (index + 1)}>
                <img className={'PlansCard-Package-Image' + (index + 1)} src={item.image} alt="" />
                <p className='PlansCard-Package-Title'>{item?.title}</p>

                {
                    item.textArray.map((textitem, i) => {
                        return <p className='PlansCard-Package-Text'><TiTick className="PlansCard-Package-Tick" /> {textitem.name}</p>
                    })
                }

                <p className='PlansCard-Package-Price'>{item?.price}</p>

            </div>


        </div>
    )
}
export default InstitutionalPlansCard
