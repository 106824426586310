import baseService from "../baseService";

export default class Organisations extends baseService {
    constructor() {
        super();
        this.schemaName = "api/organisations";
    }

    /**
     * 
     * @returns organizasyon bilgilerini döner
     * organizasyon bilgilerini döner
     */
    organisations() {
        return this.getObject("api", "organisations")
    }

    organisationsdetail(organisation_id) {
        return this.getObject("api", "organisations/" + organisation_id)
    }

    organisationPackage() {
        return this.getObject(this.schemaName, "get-packages")
    }

    NoQuestionUsers(organisationId) {
        return this.getObject(this.schemaName, "statistics/noQuestionUsers/" + organisationId)
    }



    /**
     * 
     * @param {*} organisationId organisationId verilmesi 
     * @param {*} StartDate başlangıç tarihi orn 2023-09-01
     * @param {*} EndDate bitis Tarihi orn 2023-09-02
     * 
     * organizasyonun aylık soru dağılımını gösterir
     */
    QuestionMonthStatics(organisationId, StartDate, EndDate) {
        return this.getObject(this.schemaName, "statistics/mounthlyCount/" + organisationId, "start_date=" + StartDate + "&end_date=" + EndDate)
    }

    /**
 * 
 * @param {*} organisationId org idsi
 * @param {*} ClassId Ders Idsi orn 1(fen bilgisi)
 * @param {*} StartDate başlangıç tarihi orn 2023-09-01
 * @param {*} EndDate bitis Tarihi orn 2023-09-02
 * organizasyonun derslere göre en çok soru sorduğu konular.
 */
    QuestionTopicStatics(organisationId, ClassId, StartDate, EndDate) {
        return this.getObject(this.schemaName, "statistics/getMostQuestionTopics/" + organisationId + "/" + ClassId, "start_date=" + StartDate + "&end_date=" + EndDate)
    }


    /**
     * 
     * @param {*} organisationId 
     * @returns haftalık soru dağılımını verir orneğin maaatesi 30 tane
     * organiasyonun haftalık soru dağılımını verir orneğin maaatesi 30 tane
     */
    QuestionLastWeekStatics(organisationId) {
        return this.getObject(this.schemaName, "statistics/lastDayCount/" + organisationId)
    }

    /**
     * 
     * @param {*} organisationId org idsi verilmesi 
     * @param {*} StartDate başlangıç tarihi orn 2023-09-01
     * @param {*} EndDate bitis Tarihi orn 2023-09-02
     * 
     * organizasyonun Derslere göre soru dağılımını gösterir
     */
    QuestionClassStatics(organisationId, StartDate, EndDate) {
        return this.getObject(this.schemaName, "statistics/classCount/" + organisationId, "start_date=" + StartDate + "&end_date=" + EndDate)
    }


    /**
     * 
     * @param {*} organisationId org idsi verilmesi 
     * @param {*} StartDate başlangıç tarihi orn 2023-09-01
     * @param {*} EndDate bitis Tarihi orn 2023-09-02
     * 
     * organizasyonun en çok soru soranlarn listesini verir
     */
    QuestionTopUserStatics(organisationId, StartDate, EndDate) {
        return this.getObject(this.schemaName, "statistics/topUser/" + organisationId, "start_date=" + StartDate + "&end_date=" + EndDate)
    }



}