import React, { useEffect, useState } from "react";
import './story.scss';
import { useOutletContext } from "react-router-dom";
import { GetWord } from "../../../../utils";
import { Button, Form, Input, Select } from "antd";
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';

import CloudIcon from '../../../../assets/images/cloud-computing.png';
import { Option } from "antd/es/mentions";
import Service from "../../../../Service";




const Story = () => {

  const [pageName, setPageName] = useOutletContext();
  setPageName(GetWord("Banner/Story Ekle"));

  //veri çekme için yapılması gereknler.
  const [bannerData, setBannerData] = useState([])
  const [updateList, setUpdateList] = useState(false)

  const getBannerReq = () => {

    const service = new Service();
    service.Banner.getBanners("screen=home").then((res) => {
      setBannerData(res)
      console.log(res)
    })
  }

  useEffect(() => {
    getBannerReq();
  }, [updateList])

  //************************************** */


  const { Dragger } = Upload;
  const props = {
    name: 'file',
    multiple: true,
    action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const onFinish = (value) => {
    //9
    // console.log(value)
    // const dsadas =ImgUpload(value.file ,"banners/" ,"banner1")

    const service = new Service();
    const req = {
      "id": 9
    }

    service.Banner.deleteBanner(req).then((res) => {
      setUpdateList((prev) => !prev)
    })

    // service.Banner.createBanner({
    //   "image": "tsetimage",
    //   "order": 0,
    //   "screen": "home"
    // }).then((res) => {
    //   setUpdateList((prev) => !prev)
    // }).catch((err) => { console.log(err) })
  }


  return (


    <div className="Story ">

      <div className="Story-BannerArea">
        <p className="Story-BannerArea-Title">Banner</p>
        <Form onFinish={onFinish}>
          <Form.Item
            name="bannerText"
            label="Banner Yazısı"
            extra=<p className="Story-BannerArea-BannerText-Input-Explanation">Banner için bir yazı girin</p>
            rules={
              [
                {
                  required: false,
                  message: 'Banner Yazısı İçeriği Boş Bırakılamaz!',
                },
              ]}>
            <Input.TextArea className='Story-BannerArea-BannerText-Input' showCount maxLength={50} autoSize={{
              minRows: 4,
              maxRows: 4,
            }} />
            {/* <span className="Story-BannerArea-BannerText-Input-Explanation">Banner için bir yazı girin</span> */}
          </Form.Item>

          <div className="Story-BannerArea-BannerPicture">
            <Form.Item
              name="bannerImage"
              label="Banner Resmi"
              extra=<div><p className="Story-BannerArea-BannerPicture-Upload-Explanation">Bannera ait bir resim ekleyin. </p>
                <p className="Story-BannerArea-BannerPicture-Upload-Explanation">Özellik grafiğiniz 1.024 x 500 piksel ölçülerinde, en fazla 1 MB'lık bir PNG veya JPEG dosyası olmalıdır.</p></div>
              rules={
                [
                  {
                    required: false,
                    message: 'Banner Yazısı İçeriği Boş Bırakılamaz!',
                  },
                ]}>
              <Dragger {...props} className="Story-BannerArea-BannerPicture-Upload" accept=".jpg, .jpeg, .png">
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Yüklemek için PNG veya JPEG dosyalarını buraya bırakın</p>
                <p className="ant-upload-hint">
                  <img src={CloudIcon} />
                  <p className="Story-BannerArea-BannerPicture-Upload-IconText">Yükle</p>

                </p>
              </Dragger>
              {/* <p className="Story-BannerArea-BannerPicture-Upload-Explanation">Bannera ait bir resim ekleyin.</p>
              <p className="Story-BannerArea-BannerPicture-Upload-Explanation">Özellik grafiğiniz 1.024 x 500 piksel ölçülerinde, en fazla 1 MB'lık bir PNG veya JPEG dosyası olmalıdır</p> */}
            </Form.Item>
          </div>

          <div className="Story-BannerArea-Application">
            <Form.Item
              name="application"
              label="Uygulama"
              extra=<p className="Story-BannerArea-Application-Explanation">Gönderilecek uygulamayı seçin</p>
              rules={
                [
                  {
                    required: false,
                    message: 'Uygulama İçeriği Boş Bırakılamaz!',
                  },
                ]}>
              <Select defaultValue="Option1" className='Story-BannerArea-Application-Input' >
                <Option value="Option1"  >
                  Öğrenci</Option>
              </Select>
              {/* <p className="Story-BannerArea-Application-Explanation">Gönderilecek uygulamayı seçin</p> */}
            </Form.Item>

          </div>

          <Button className='Story-BannerArea-Button' htmlType="submit">
            <span className='Story-BannerArea-Button-Text'>Ekle</span>
          </Button>

        </Form>
      </div>



      <div className="Story-StoryArea">
        <p className="Story-StoryArea-Title">Story</p>

        <div className="Story-StoryArea-StoryPicture">

          <Form.Item
            name="storyImage"
            label="Story Resmi"
            extra=<div><p className="Story-StoryArea-StoryPicture-Upload-Explanation">Story'ye ait bir resim ekleyin.</p>
              <p className="Story-StoryArea-StoryPicture-Upload-Explanation">Özellik grafiğiniz 1.024 x 500 piksel ölçülerinde, en fazla 1 MB'lık bir PNG veya JPEG dosyası olmalıdır"</p></div>
            rules={
              [
                {
                  required: false,
                  message: 'Banner Yazısı İçeriği Boş Bırakılamaz!',
                },
              ]}>
            <Dragger {...props} className="Story-StoryArea-StoryPicture-Upload" accept=".jpg, .jpeg, .png">
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Yüklemek için PNG veya JPEG dosyalarını buraya bırakın</p>
              <p className="ant-upload-hint">
                <img src={CloudIcon} />
                <p className="Story-StoryArea-StoryPicture-Upload-IconText">Yükle</p>

              </p>
            </Dragger>
            {/* <p className="Story-StoryArea-StoryPicture-Upload-Explanation">Story'ye ait bir resim ekleyin.</p>
            <p className="Story-StoryArea-StoryPicture-Upload-Explanation">Özellik grafiğiniz 1.024 x 500 piksel ölçülerinde, en fazla 1 MB'lık bir PNG veya JPEG dosyası olmalıdır</p> */}
          </Form.Item>
        </div>

        <div className="Story-StoryArea-Application">
          <Form.Item
            name="application"
            label="Uygulama"
            extra=<p className="Story-StoryArea-Application-Explanation">Gönderilecek uygulamayı seçin</p>
            rules={
              [
                {
                  required: true,
                  message: 'Uygulama İçeriği Boş Bırakılamaz!',
                },
              ]}>
            <Select defaultValue="Option1" className='Story-StoryArea-Application-Input' >
              <Option value="Option1"  >
                Öğrenci</Option>
            </Select>
            {/* <p className="Story-StoryArea-Application-Explanation">Gönderilecek uygulamayı seçin</p> */}
          </Form.Item>

        </div>
        <button className='Story-StoryArea-Button'>
          <span className='Story-StoryArea-Button-Text'>Ekle</span>
        </button>


      </div>
    </div>
  );
};

export default Story;
