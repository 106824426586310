import React from 'react'
import './AddCard.scss'
import { Form, Tabs, message, Upload, Select, Input } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';
import CloudIcon from '../../../../../assets/images/cloud-computing.png';
import { Option } from 'antd/es/mentions';
import { useOutletContext } from 'react-router-dom';
import { GetWord } from '../../../../../utils';
import CurrentCard from './CurrentCard/CurrentCard';


const AddCard = () => {

    const [pageName, setPageName] = useOutletContext();
    setPageName(GetWord("Bilgi Kartı Ekle"));


    const NewAdd = () => {

        const { Dragger } = Upload;
        const props = {
            name: 'file',
            multiple: true,
            action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
            onChange(info) {
                const { status } = info.file;
                if (status !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
                if (status === 'done') {
                    message.success(`${info.file.name} file uploaded successfully.`);
                } else if (status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
            onDrop(e) {
                console.log('Dropped files', e.dataTransfer.files);
            },
        };

        return (
            <div className='AddCard'>
                <div className='AddCard-NewAdd'>


                    <div className="AddCard-NewAdd-Lessons">
                        <Form.Item
                            name="lesson"
                            label="Ders"
                            rules={
                                [
                                    {
                                        required: true,
                                        message: 'Ders İçeriği Boş Bırakılamaz!',
                                    },
                                ]}>
                            <Select defaultValue="Option1" className='AddCard-NewAdd-Lessons-Input' >
                                <Option value="Option1"  >   </Option>
                            </Select>
                            <p className="AddCard-NewAdd-Lessons-Explanation">Teste ait dersi seçiniz</p>
                        </Form.Item>
                    </div>

                    <div className="AddCard-NewAdd-Subject">
                        <Form.Item
                            name="subject"
                            label="Konu"
                            rules={
                                [
                                    {
                                        required: true,
                                        message: 'Konu İçeriği Boş Bırakılamaz!',
                                    },
                                ]}>
                            <Select defaultValue="Option1" className='AddCard-NewAdd-Subject-Input' >
                                <Option value="Option1"  >   </Option>
                            </Select>
                            <p className="AddCard-NewAdd-Subject-Explanation">Teste ait konuyu seçiniz</p>
                        </Form.Item>
                    </div>

                    <div className="AddCard-NewAdd-NumberOfCard">
                        <Form.Item
                            name="numberOfCard"
                            label="Kart Sayısı"
                            rules={
                                [
                                    {
                                        required: true,
                                        message: 'Konu İçeriği Boş Bırakılamaz!',
                                    },
                                ]}>
                            <Select defaultValue="Option1" className='AddCard-NewAdd-NumberOfCard-Input' >
                                <Option value="Option1"  >   </Option>
                            </Select>
                            <p className="AddCard-NewAdd-NumberOfCard-Explanation">Kartın soru sayısını seçiniz</p>
                        </Form.Item>
                    </div>

                    <div className='AddCard-NewAdd-ContentOfCard'>
                        <p className='AddCard-NewAdd-ContentOfCard-Title'>1. Kart</p>
                        <Form.Item
                            name="contentsOne"
                            label="Kart İçeriği"
                            rules={
                                [
                                    {
                                        required: true,
                                        message: 'İçerik Boş Bırakılamaz!',
                                    },
                                ]}>
                            <Input.TextArea className='AddSubject-NewAdd-Contents-Input' showCount autoSize={{
                                minRows: 5,
                                maxRows: 5,
                            }} />
                            <p className="AddSubject-NewAdd-Contents-Explanation">Kart içeriğini yazınız.</p>
                        </Form.Item>
                    </div>

                    <div className='AddCard-NewAdd-ContentOfCard'>
                        <p className='AddCard-NewAdd-ContentOfCard-Title'>2. Kart</p>
                        <Form.Item
                            name="contentsOne"
                            label="Kart İçeriği"
                            rules={
                                [
                                    {
                                        required: true,
                                        message: 'İçerik Boş Bırakılamaz!',
                                    },
                                ]}>
                            <Input.TextArea className='AddSubject-NewAdd-Contents-Input' showCount autoSize={{
                                minRows: 5,
                                maxRows: 5,
                            }} />
                            <p className="AddSubject-NewAdd-Contents-Explanation">Kart içeriğini yazınız.</p>
                        </Form.Item>
                    </div>


                    <button className='AddCard-NewAdd-Button'>
                        <span className='AddCard-NewAdd-Button-Text'>Ekle</span>
                    </button>

                </div>
            </div>
        )

    };


    const Available = () => {

        const CurrentCardData = [
            {
                image: "https://e1.pxfuel.com/desktop-wallpaper/460/879/desktop-wallpaper-geography-geography-pc-backgrounds-geography.jpg",
                cardName: "Coğrafya",
                dateIcon: <svg data-name="vuesax/bulk/clock" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 13.931 13.931">
                    <path d="M11.609 5.8A5.8 5.8 0 1 1 5.8 0a5.8 5.8 0 0 1 5.809 5.8z" transform="translate(1.161 1.161)" style={{ opacity: 0.4, fill: 'currentColor' }} />
                    <path data-name="Vector" d="M2.8 5.323a.389.389 0 0 1-.221-.064L.778 4.185A1.712 1.712 0 0 1 0 2.815V.435A.439.439 0 0 1 .435 0a.439.439 0 0 1 .436.435v2.38a.858.858 0 0 0 .354.621l1.8 1.074a.435.435 0 0 1 .151.6.447.447 0 0 1-.376.213z" transform="translate(6.321 3.924)" style={{ fill: 'currentColor' }} />
                </svg>
                ,
                date: "12.07.2022",
                subject: "Yer Şekilleri",
                section: "Bilgi Kartı",
            },
            {
                image: "https://e1.pxfuel.com/desktop-wallpaper/460/879/desktop-wallpaper-geography-geography-pc-backgrounds-geography.jpg",
                cardName: "Coğrafya",
                dateIcon: <svg data-name="vuesax/bulk/clock" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 13.931 13.931">
                    <path d="M11.609 5.8A5.8 5.8 0 1 1 5.8 0a5.8 5.8 0 0 1 5.809 5.8z" transform="translate(1.161 1.161)" style={{ opacity: 0.4, fill: 'currentColor' }} />
                    <path data-name="Vector" d="M2.8 5.323a.389.389 0 0 1-.221-.064L.778 4.185A1.712 1.712 0 0 1 0 2.815V.435A.439.439 0 0 1 .435 0a.439.439 0 0 1 .436.435v2.38a.858.858 0 0 0 .354.621l1.8 1.074a.435.435 0 0 1 .151.6.447.447 0 0 1-.376.213z" transform="translate(6.321 3.924)" style={{ fill: 'currentColor' }} />
                </svg>
                ,
                date: "12.07.2022",
                subject: "Yer Şekilleri",
                section: "Bilgi Kartı",
            },
            {
                image: "https://e1.pxfuel.com/desktop-wallpaper/460/879/desktop-wallpaper-geography-geography-pc-backgrounds-geography.jpg",
                cardName: "Coğrafya",
                dateIcon: <svg data-name="vuesax/bulk/clock" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 13.931 13.931">
                    <path d="M11.609 5.8A5.8 5.8 0 1 1 5.8 0a5.8 5.8 0 0 1 5.809 5.8z" transform="translate(1.161 1.161)" style={{ opacity: 0.4, fill: 'currentColor' }} />
                    <path data-name="Vector" d="M2.8 5.323a.389.389 0 0 1-.221-.064L.778 4.185A1.712 1.712 0 0 1 0 2.815V.435A.439.439 0 0 1 .435 0a.439.439 0 0 1 .436.435v2.38a.858.858 0 0 0 .354.621l1.8 1.074a.435.435 0 0 1 .151.6.447.447 0 0 1-.376.213z" transform="translate(6.321 3.924)" style={{ fill: 'currentColor' }} />
                </svg>
                ,
                date: "12.07.2022",
                subject: "Yer Şekilleri",
                section: "Bilgi Kartı",
            },
            {
                image: "https://e1.pxfuel.com/desktop-wallpaper/460/879/desktop-wallpaper-geography-geography-pc-backgrounds-geography.jpg",
                cardName: "Coğrafya",
                dateIcon: <svg data-name="vuesax/bulk/clock" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 13.931 13.931">
                    <path d="M11.609 5.8A5.8 5.8 0 1 1 5.8 0a5.8 5.8 0 0 1 5.809 5.8z" transform="translate(1.161 1.161)" style={{ opacity: 0.4, fill: 'currentColor' }} />
                    <path data-name="Vector" d="M2.8 5.323a.389.389 0 0 1-.221-.064L.778 4.185A1.712 1.712 0 0 1 0 2.815V.435A.439.439 0 0 1 .435 0a.439.439 0 0 1 .436.435v2.38a.858.858 0 0 0 .354.621l1.8 1.074a.435.435 0 0 1 .151.6.447.447 0 0 1-.376.213z" transform="translate(6.321 3.924)" style={{ fill: 'currentColor' }} />
                </svg>
                ,
                date: "12.07.2022",
                subject: "Yer Şekilleri",
                section: "Bilgi Kartı",
            },
        ]


        return (

            <div className='AddCard-CurrentCard'>

                {
                    CurrentCardData.map((item, i) => {

                        return <CurrentCard item={item} key={i} index={i} />
                    }
                    )
                }
            </div>
        )


    };



    const items = [
        {
            key: '1',
            label: 'Bilgi Kartı Ekle',
            children: <NewAdd />,
        },
        {
            key: '2',
            label: 'Mevcut Bilgi Kartları',
            children: <Available />,
        },
    ];

    return (
        <div className='AddCard'>


            <div><Tabs defaultActiveKey="1" items={items} />
            </div>
        </div>
    )
}

export default AddCard