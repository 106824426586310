import React from 'react'
import { GetWord } from '../../../../../utils';
import { useOutletContext } from 'react-router-dom';
import './applications.scss';
import * as AiIcons from "react-icons/ai";
import { Select, Table } from 'antd';

const Applications = () => {
    const [pageName, setPageName] = useOutletContext();
    setPageName(GetWord("Eğitmen Başvuruları"));

    const ListColumns = [
        {
            title: 'İsim',
            dataIndex: 'name',

        },
        {
            title: 'E-Posta Adresi',
            dataIndex: 'eMail',
        },
        {
            title: 'Üniversite',
            dataIndex: 'university',
        },
        {
            title: 'Bölüm',
            dataIndex: 'section',
        },

        {
            title: 'Öğretim Durumu',
            dataIndex: 'teachingStatus',
        },

        {
            title: 'Başvurulan Dersler',
            dataIndex: 'coursesApplied',
        },
        {
            title: 'Ders Kaldır',
            dataIndex: 'removeLesson',
        },
        {
            title: 'İşlem',
            dataIndex: 'button',
        },
    ];
    const ListData = [
        {
            name: 'Mali KARA',
            eMail: 'deneme@deneme.com',
            university: 'Pamukkale Üniversitesi',
            section: 'Fizik',
            teachingStatus: 'Öğretmen',
            coursesApplied: 'Matematik, Fizik, Kimya',
            removeLesson:
                < Select defaultValue="Seç" style={{ width: 120 }} options={
                    [
                        { value: 'matematik', label: 'Matematik' },
                        { value: 'turkce', label: 'Türkçe' },

                    ]} />,
            button: <div className="ReportQuestions-Button">
                <button className="ReportQuestions-Button-SeeQuestion" onClick={<Applications />} style={{ cursor: 'pointer' }}><span className="ReportQuestions-Button-Text">Onayla</span> </button>
                <button className="ReportQuestions-Button-Delete" onClick={<Applications />} style={{ cursor: 'pointer' }}><span className="ReportQuestions-Button-Text">Reddet</span></button>
            </div>,
        },

    ];


    return (
        <div>
            <div>
                <div className="Students-Input">
                    <input
                        type="search"
                        className="Students-Input-Style"
                        placeholder={GetWord("Ara...")}
                    />
                    <span className="Students-Input-SearchLogo">
                        <AiIcons.AiOutlineSearch className="Students-Input-SearchLogo-Style" />
                    </span>
                </div>


                <div className="table">

                    <Table

                        columns={ListColumns}
                        dataSource={ListData}
                    />
                </div>



            </div>
        </div>

    );

}

export default Applications