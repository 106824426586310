import React, { useEffect, useState } from 'react'

import "./institutionalDashboard.scss"
import InsDashBoardCard from './InsDasboardCard/InsDashBoardCard'
import { PiGraduationCap } from "react-icons/pi"
import { LiaUserCheckSolid } from "react-icons/lia"
import { TbChecklist } from "react-icons/tb"
import { FaChalkboardTeacher } from "react-icons/fa"
import { useDispatch } from 'react-redux'
import { setPageName } from '../../../../stores/site'
import InsStudentsList from '../../components/StudentsList/InsStudentsList'
import { Column } from '@ant-design/plots'
import { Select } from 'antd'
import { Option } from 'antd/es/mentions'
import Service from '../../../../Service'
import { GetWord } from '../../../../utils'
import moment from 'moment'
import Loading from '../../../shared/loading/loading'


const InstitutionalDashboard = () => {
    const [GpLoading1, setGpLoading1] = useState(true);
    const [GpLoading2, setGpLoading2] = useState(true);
    const [GpLoading3, setGpLoading3] = useState(true);
    const [GpLoading4, setGpLoading4] = useState(true);
    const [StudentList, setStudentList] = useState();
    const [LastWeekDailyData, setLastWeekDailyData] = useState();
    const [LessonData, setLessonData] = useState();
    const [organisation, setOrganisation] = useState();
    const [OrganisationsDetail, setOrganisationsDetail] = useState();




    const getOrganisation = () => {
        const service = new Service();
        service.Organisations.organisations().then((res) => {
            setOrganisation(res);
        })
    }


    const getOrganisationsDetail = () => {
        const service = new Service();
        service.Organisations.organisationsdetail(1).then((res) => {
            setOrganisationsDetail(res);
            console.log(res)
        })
    }



    const getLastWeekDailyData = () => {
        const service = new Service();
        service.Organisations.QuestionLastWeekStatics(1).then((res) => {
            setLastWeekDailyData(Object.entries(res).map(([key, value]) => ({ value: value, type: GetWord(key) })))
            setGpLoading1(false)
        })
    }


    const getLessonData = (startDate, endDate) => {
        const service = new Service();
        service.Organisations.QuestionClassStatics(1, startDate, endDate).then((res) => {
            setLessonData(Object.entries(res).map(([key, value]) => ({ value: value, type: key })))
            setGpLoading2(false)

        })
    }

    const getStudent = () => {
        const service = new Service();
        service.Student.students().then((res) => {
            setStudentList(res);
        })
    }


    const dispatch = useDispatch();
    dispatch(setPageName("Genel Bakış"))


    useEffect(() => {
        getStudent();
        getLastWeekDailyData();
        getLessonData(moment().add(-1, "M").format("YYYY-MM-DD"), moment().add(+1, "days").format("YYYY-MM-DD"));
        getOrganisation();
        getOrganisationsDetail();

    }, [])


    const LessonSelected = (value) => {
        getLessonData(moment().add(-parseInt(value), "M").format("YYYY-MM-DD"), moment().add(+1, "days").format("YYYY-MM-DD"));
    }

    const DailyConfig = {
        data: LastWeekDailyData,
        xField: 'type',
        yField: 'value',
        label: {
            // 可手动配置 label 数据标签位置
            position: 'middle',
            // 'top', 'bottom', 'middle',
            // 配置样式
            style: {
                fill: '#FFFFFF',
                opacity: 0.6,
            },
        },
        xAxis: {
            label: {
                autoHide: false,
                autoRotate: true,
            },
        },
        meta: {
            type: {
                alias: 'Soru Sayısı',
            },
            value: {
                alias: 'Soru Sayısı',
            },
        },
    };


    const ByLessonsConfig = {
        data: LessonData,
        xField: 'type',
        yField: 'value',
        label: {
            // 可手动配置 label 数据标签位置
            position: 'middle',
            // 'top', 'bottom', 'middle',
            // 配置样式
            style: {
                fill: '#FFFFFF',
                opacity: 0.6,
            },
        },
        xAxis: {
            label: {
                autoHide: false,
                autoRotate: true,
            },
        },
        meta: {
            type: {
                alias: 'Soru Sayısı',
            },
            value: {
                alias: 'Soru Sayısı',
            },
        },
    };


    const CardData = [
        {
            icon: <PiGraduationCap />,
            bigTitle: OrganisationsDetail && OrganisationsDetail.studentCount,
            smallTitle: "Toplam Öğrenci",

        },
        {
            icon: <LiaUserCheckSolid />,
            bigTitle: StudentList && StudentList.filter(item => item.isActivation).length,
            smallTitle: "Aktifleştirilmiş Öğrenci",

        },
        {
            icon: <TbChecklist />,
            bigTitle: OrganisationsDetail && OrganisationsDetail.totalQuestionCount,
            smallTitle: "Toplam Soru",

        },
        {
            icon: <FaChalkboardTeacher />,
            bigTitle: OrganisationsDetail && OrganisationsDetail.teacherCount,
            smallTitle: "Öğretmen Sayısı",

        },
    ]





    return (
        <div>
            <div className="Main-Dashboard">
                {
                    CardData.map((item, i) => {

                        return <InsDashBoardCard item={item} key={i} index={i} />
                    }
                    )
                }

            </div >

            <div className='Main-Dashboard-Graphic'>
                <div className="Main-Dashboard-Graphic-1">
                    <div className="Main-Dashboard-Graphic-1-Select">
                        <p className="Main-Dashboard-Graphic-1-Title">Günlük Soru Dağılımı</p>
                        {/* <Select defaultValue="Option1" className='Main-Dashboard-Graphic-1-Select-Style'>
                            <Option value="Option1" >Son 1 hafta</Option>
                        </Select> */}
                    </div>
                    {GpLoading1 ? <Loading small={true} /> : <Column {...DailyConfig} />}
                </div>

                <div className="Main-Dashboard-Graphic-2">
                    <div className="Main-Dashboard-Graphic-1-Select">
                        <p className="Main-Dashboard-Graphic-2-Title">
                            Derslere Göre Soru Dağılımı
                        </p>
                        <Select defaultValue="1" className='Main-Dashboard-Graphic-1-Select-Style' onChange={LessonSelected}>
                            <Option value="1" >Son 1 ay</Option>
                            <Option value="3" >Son 3 ay</Option>
                            <Option value="6" >Son 6 ay</Option>
                            <Option value="12" >Son 1 yıl</Option>
                        </Select>
                    </div>
                    {GpLoading2 ? <Loading small={true} /> : <Column {...ByLessonsConfig} />}
                </div>
            </div>
            <div className='Main-Dashboard-StudentList'> <p className="Main-Dashboard-StudentList-Title" >Kurum Öğrencileri</p>
                <InsStudentsList data={StudentList} />

            </div>

        </div>
    )
}

export default InstitutionalDashboard