import React, { useEffect, useState } from 'react';
import "./InstitutionalStudentDetail.scss";
import { setPageName } from '../../../../../stores/site';
import { useDispatch } from 'react-redux';
import { Button, Form, Select } from 'antd';
import { FaCircle } from "react-icons/fa";
import { Column, Pie } from '@ant-design/plots';
import { Option } from 'antd/es/mentions';
import Service from '../../../../../Service';
import Loading from '../../../../shared/loading/loading';
import { useParams } from 'react-router-dom';
import { GetWord } from "../../../../../utils";
import moment from 'moment';



const InstitutionalStudentDetail = () => {
    const [loading, setLoading] = useState(true);
    const [loadingGP1, setLoadingGP1] = useState(true);
    const [GpLoading1, setGpLoading1] = useState(true);
    const [GpLoading2, setGpLoading2] = useState(true);
    const [GpLoading3, setGpLoading3] = useState(true);

    const [user, setUser] = useState();
    const [LastWeekDailyData, setLastWeekDailyData] = useState();
    const [QuestionMonthData, setQuestionMonthData] = useState();
    const [QuestionTopicData, setQuestionTopicData] = useState();
    const [LessonData, setLessonData] = useState();
    const [ClassList, setClassList] = useState();
    const [SelectedClassId, setSelectedClassId] = useState();
    const [SelectedMonth, setSelectedMonth] = useState(1);


    const { id } = useParams();

    const getUser = () => {
        const service = new Service();
        service.Profiles.userProfile(id).then((res) => {
            setLoading(false);
            setUser(res);
        })
    }

    const getClassesList = () => {
        const service = new Service();
        service.Classes.getClass().then((res) => {
            setClassList(res);
            if (res && res.length > 0) {
                setSelectedClassId(res[0].id)
            }
        })
    }


    const getLastWeekDailyData = () => {
        const service = new Service();
        service.Student.studentQuestionLastWeekStatics(id).then((res) => {
            setLastWeekDailyData(Object.entries(res).map(([key, value]) => ({ value: value, type: GetWord(key) })))
            setGpLoading1(false)
        })
    }



    const getQuestionMonthData = (startDate, endDate) => {
        const service = new Service();
        service.Student.studentQuestionMonthStatics(id, startDate, endDate).then((res) => {
            setQuestionMonthData(Object.entries(res).map(([key, value]) => ({ value: value, type: GetWord(key) })).reverse())
            setGpLoading2(false)
        })
    }

    const getLessonData = (startDate, endDate) => {
        const service = new Service();
        service.Student.studentQuestionClassStatics(id, startDate, endDate).then((res) => {
            setLessonData(Object.entries(res).map(([key, value]) => ({ value: value, type: key })))
            setGpLoading3(false)
        })
    }


    const getQuestionTopicData = (classId, startDate, endDate) => {
        const service = new Service();
        service.Student.studentQuestionTopicStatics(id, classId, startDate, endDate).then((res) => {
            setQuestionTopicData(Object.entries(res).map(([key, value]) => ({ value: value, type: key })))
            setLoadingGP1(false);

        })
    }

    const [form] = Form.useForm();
    const finish = (value) => {
    }
    const dispatch = useDispatch();
    dispatch(setPageName("Öğrenci Detayı"))

    useEffect(() => {
        getUser();
        getLastWeekDailyData();
        getQuestionMonthData(moment().add(-1, "y").format("YYYY-MM-DD"), moment().add(+1, "M").format("YYYY-MM-DD"));
        getLessonData(moment().add(-1, "M").format("YYYY-MM-DD"), moment().add(+1, "days").format("YYYY-MM-DD"));
        getClassesList();
        getQuestionTopicData(SelectedClassId, moment().add(-1, "y").format("YYYY-MM-DD"), moment().add(+1, "M").format("YYYY-MM-DD"));


    }, [])

    const WeekConfig = {
        data: LastWeekDailyData,
        xField: 'type',
        yField: 'value',
        label: {
            // 可手动配置 label 数据标签位置
            position: 'middle',
            // 'top', 'bottom', 'middle',
            // 配置样式
            style: {
                fill: '#FFFFFF',
                opacity: 0.6,
            },
        },
        xAxis: {
            label: {
                autoHide: true,
                autoRotate: false,
            },
        },
        meta: {
            type: {
                alias: 'Soru Sayısı',
            },
            value: {
                alias: 'Soru Sayısı',
            },
        },
    };

    const TotalQuestionConfig = {
        data: QuestionMonthData,
        xField: 'type',
        yField: 'value',
        label: {
            // 可手动配置 label 数据标签位置
            position: 'middle',
            // 'top', 'bottom', 'middle',
            // 配置样式
            style: {
                fill: '#FFFFFF',
                opacity: 0.6,
            },
        },
        xAxis: {
            label: {
                autoHide: true,
                autoRotate: true,
            },
        },
        meta: {
            type: {
                alias: 'Toplam Soru',
            },
            value: {
                alias: 'Toplam Soru',
            },
        },
    };

    const MostAskedTopicsConfig = {
        appendPadding: 10,
        data: QuestionTopicData,
        angleField: 'value',
        colorField: 'type',
        radius: 1,
        innerRadius: 0.6,
        label: {
            type: 'inner',
            offset: '-50%',
            content: '{value}',
            style: {
                textAlign: 'center',
                fontSize: 14,
            },
        },
        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
        ],
        statistic: {
            title: false,
            content: {
                style: {
                    whiteSpace: 'pre-wrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                content: '',
            },
        },
    };

    const QuestionByLessonsConfig = {
        appendPadding: 10,
        data: LessonData,
        angleField: 'value',
        colorField: 'type',
        radius: 1,
        innerRadius: 0.6,
        label: {
            type: 'inner',
            offset: '-50%',
            content: '{value}',
            style: {
                textAlign: 'center',
                fontSize: 14,
            },
        },
        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
        ],
        statistic: {
            title: false,
            content: {
                style: {
                    whiteSpace: 'pre-wrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                content: '',
            },
        },
    };

    const LessonSelected = (value) => {
        getLessonData(moment().add(-parseInt(value), "M").format("YYYY-MM-DD"), moment().add(+1, "days").format("YYYY-MM-DD"));
    }

    const TopicMonthtSelected = (value) => {
        setSelectedMonth(parseInt(value))
        setLoadingGP1(true);
    }

    const TopicLessonSelected = (value) => {
        setSelectedClassId(parseInt(value))
        setLoadingGP1(true);
    }

    useEffect(() => {
        getQuestionTopicData(SelectedClassId, moment().add(-SelectedMonth, "M").format("YYYY-MM-DD"), moment().add(+1, "days").format("YYYY-MM-DD"));
    }, [SelectedClassId, SelectedMonth])

    if (loading)
        return <Loading />

    return (
        <div>
            <div className='StudentDetail-Main-FirstArea'>
                <div className='StudentDetail-Main-FirstArea-StudentInformation'>
                    <div className='StudentDetail-Main-FirstArea-StudentInformation-info'>
                        <div className='StudentDetail-Main-FirstArea-Center'>
                            <img src={user?.image} alt=""
                                className="StudentDetail-Main-FirstArea-StudentInformation-Picture" />
                            <p className="StudentDetail-Main-FirstArea-StudentInformation-NameSurname">{user?.name + " " + user?.surname}</p>
                        </div>
                        <div className="StudentDetail-Main-FirstArea-StudentInformation-RemainingQuestion-Center">
                            <FaCircle className="StudentDetail-Main-FirstArea-StudentInformation-RemainingQuestion-Icon" />
                            <p className="StudentDetail-Main-FirstArea-StudentInformation-RemainingQuestion">{user?.videoAnswerSolutionRight + user?.answerSolutionRight} Soru Hakkı</p>
                        </div>
                        <div>
                            <Form form={form} onFinish={finish} name="basic" labelCol={{
                                span: 8,
                            }}
                                wrapperCol={{
                                    span: 18,
                                }}
                            >

                                <div div className='StudentDetail-Main-FirstArea-StudentInformation-Form'>
                                    <div className='StudentDetail-Main-FirstArea-StudentInformation-Form-Input'>
                                        <p className='StudentDetail-Main-FirstArea-StudentInformation-Form-Title'>İsim Soyisim</p>
                                        <Form.Item
                                            name="studentNameSurname"
                                        >
                                            <p className="StudentDetail-Main-FirstArea-StudentInformation-Form-Text" >{user.name + " " + user.surname}</p>
                                        </Form.Item>
                                    </div>

                                    <div className='StudentDetail-Main-FirstArea-StudentInformation-Form-Input'>
                                        <p className='StudentDetail-Main-FirstArea-StudentInformation-Form-Title'>E-Posta Adresi</p>
                                        <Form.Item
                                            name="studentEmailAdress"
                                        >
                                            <p className="StudentDetail-Main-FirstArea-StudentInformation-Form-Text" >{user?.email}</p>
                                        </Form.Item>
                                    </div>
                                </div>

                                <div div className='StudentDetail-Main-FirstArea-StudentInformation-Form'>
                                    <div className='StudentDetail-Main-FirstArea-StudentInformation-Form-Input'>
                                        <p className='StudentDetail-Main-FirstArea-StudentInformation-Form-Title'>TC Kimlik Numarası</p>
                                        <Form.Item
                                            name="studentIDNumber"
                                        >

                                            <p className="StudentDetail-Main-FirstArea-StudentInformation-Form-Text" >{user?.identityNumber}</p>
                                        </Form.Item>
                                    </div>

                                    <div className='StudentDetail-Main-FirstArea-StudentInformation-Form-Input'>
                                        <p className='StudentDetail-Main-FirstArea-StudentInformation-Form-Title'>Telefon Numarası</p>
                                        <Form.Item
                                            name="studentPhoneNumber"
                                        >
                                            <p className="StudentDetail-Main-FirstArea-StudentInformation-Form-Text" >{user?.phone}</p>
                                        </Form.Item>
                                    </div>
                                </div>

                                <div div className='StudentDetail-Main-FirstArea-StudentInformation-Form'>
                                    <div className='StudentDetail-Main-FirstArea-StudentInformation-Form-Input'>
                                        <p className='StudentDetail-Main-FirstArea-StudentInformation-Form-Title'>Doğum Tarihi</p>
                                        <Form.Item
                                            name="studentBirthDate"
                                        >
                                            <p className="StudentDetail-Main-FirstArea-StudentInformation-Form-Text" >-</p>
                                        </Form.Item>
                                    </div>

                                    <div className='StudentDetail-Main-FirstArea-StudentInformation-Form-Input'>
                                        <p className='StudentDetail-Main-FirstArea-StudentInformation-Form-Title'>Öğrenci Durumu</p>
                                        <Form.Item
                                            name="studentStatus"
                                        >
                                            <p className="StudentDetail-Main-FirstArea-StudentInformation-Form-Text" >{user?.is_active ? "Aktifleştirilmiş" : "Aktifleştirilmemiş"}</p>
                                        </Form.Item>
                                    </div>
                                </div>

                                <Form.Item>
                                    <Button htmlType='submit' className="StudentDetail-Main-FirstArea-StudentInformation-DeleteButton">Öğrenciyi Kurumdan Kaldır</Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                    <div className='StudentDetail-Main-SecondArea-Grafic-1'>
                        <div className="StudentDetail-Main-SecondArea-Grafic-1-Select">
                            <p className="StudentDetail-Main-SecondArea-Grafic-1-Text">Derslere Göre Soru Dağılımları</p>
                            <Select defaultValue="1" className='StudentDetail-Main-SecondArea-Grafic-1-Select-Style' onChange={LessonSelected}>
                                <Option value="1" >Son 1 ay</Option>
                                <Option value="3" >Son 3 ay</Option>
                                <Option value="6" >Son 6 ay</Option>
                                <Option value="12" >Son 1 yıl</Option>
                            </Select>
                        </div>
                        {GpLoading3 ? <Loading small={true} /> : <Pie {...QuestionByLessonsConfig} />}
                    </div>
                </div>

                <div className='StudentDetail-Main-FirstArea-Grafic'>
                    <div className='StudentDetail-Main-FirstArea-Grafic-1'>
                        <div className="StudentDetail-Main-FirstArea-Grafic-1-Select">
                            <p className="StudentDetail-Main-FirstArea-Grafic-1-Text">Haftalık Soru Dağılımı</p>
                            {/* <Select defaultValue="1" className='StudentDetail-Main-FirstArea-Grafic-1-Select-Style' >
                                <Option value="1" >Son 1 hafta</Option>

                            </Select> */}
                        </div>
                        {GpLoading1 ? <Loading small={true} /> : <Column {...WeekConfig} />}
                    </div>
                    <div className='StudentDetail-Main-FirstArea-Grafic-2'>

                        <p className="StudentDetail-Main-FirstArea-Grafic-1-Text">Toplam Soru Dağılımı</p>
                        {GpLoading2 ? <Loading small={true} /> : <Column {...TotalQuestionConfig} />}


                    </div>
                    <div className='StudentDetail-Main-SecondArea-Grafic-2'>
                        <div className="StudentDetail-Main-SecondArea-Grafic-2-Select">
                            <p className="StudentDetail-Main-SecondArea-Grafic-2-Text">En Çok Soru Sorulan Konular</p>
                            <div className="StudentDetail-Main-SecondArea-Grafic-2-Select">

                                {!ClassList ? <Loading small={true} /> :
                                    <Select defaultValue={ClassList && ClassList[0].id} className='StudentDetail-Main-SecondArea-Grafic-1-Select-Style' onChange={TopicLessonSelected}>
                                        {
                                            ClassList && ClassList.map((item, value) => (<Option value={item.id} >{item.name}</Option>))
                                        }

                                    </Select>
                                }
                                <Select defaultValue="1" className='StudentDetail-Main-SecondArea-Grafic-1-Select-Style' onChange={TopicMonthtSelected}>
                                    <Option value="1" >Son 1 ay</Option>
                                    <Option value="3" >Son 3 ay</Option>
                                    <Option value="6" >Son 6 ay</Option>
                                    <Option value="12" >Son 1 yıl</Option>
                                </Select>

                            </div>
                        </div>
                        {loadingGP1 ? <Loading small={true} /> : <Pie {...MostAskedTopicsConfig} />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InstitutionalStudentDetail