import React from "react";
import './sellpacket.scss';
import { GetWord } from "../../../../utils";
import { Link, useOutletContext } from "react-router-dom";
import { Select, Table } from "antd";
import { Option } from "antd/es/mentions";

const Sellpacket = () => {
  const [pageName, setPageName] = useOutletContext();
  setPageName(GetWord("Satılan Paketler"));

  const ListColumns = [
    {
      title: 'E-posta Adı',
      dataIndex: 'eMail',

    },
    {
      title: 'Paket Adı',
      dataIndex: 'packetName',
    },
    {
      title: 'Alış Tarihi',
      dataIndex: 'purchaseDate',
    },
    {
      title: 'Bitiş Tarihi',
      dataIndex: 'endDate',
    },
    {
      title: 'Kalan Hak',
      dataIndex: 'remainingRight',
    },
    {
      title: 'Ücret',
      dataIndex: 'price',
    },
    {
      title: 'İşlemler',
      dataIndex: 'button',
    },

  ];


  const ListData = [
    {
      eMail: 'deneme@d.com',
      packetName: 'Aylık 100 Soru',
      purchaseDate: '28.12.2021',
      endDate: '28.12.2021',
      remainingRight: '32',
      price: '32₺',
      button: <button className="SellPacket-SecondArea-List-Button-DetailButton" onClick={<Sellpacket />} style={{ cursor: 'pointer' }}><span className="SellPacket-SecondArea-List-Button-DetailButton-Text">Detay</span ></button >,
    },
  ];
  return (


    <div className="SellPacket">
      <div className="SellPacket-FirstArea">
        <div className="SellPacket-FirstArea-IconSelect">
          <div className='SellPacket-FirstArea-IconSelect-Icon'>
            <svg viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
              <path fill="currentColor" d="m0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-272h-448zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12zm-128-128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12zm-128-128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12zm336-332h-48v-48c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48h-128v-48c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48h-48c-26.5 0-48 21.5-48 48v48h448v-48c0-26.5-21.5-48-48-48z" />
            </svg>
          </div>
          <Select defaultValue="Option1" className='SellPacket-FirstArea-IconSelect-Select CustomPlaceholder' >
            <Option value="Option1"  >
              Tüm Zamanlar</Option>
          </Select>
        </div>
      </div>

      <div className="SellPacket-SecondArea">
        <div className="SellPacket-SecondArea-List">
          <Table
            columns={ListColumns}
            dataSource={ListData}
          />
        </div>
      </div>
    </div>



  );
};
export default Sellpacket;

