import React from 'react'


const InsDashBoardCard = ({ item, index }) => {
    return (
        <div className={"Main-Dashboard-Card Main-Dashboard-Card-" + (index + 1)}>
            <div className={'Main-Dashboard-Card-Icon Main-Dashboard-Card-Icon-' + (index + 1)}>{item.icon}</div>
            <p className='Main-Dashboard-Card-BigTitle'>{item.bigTitle}</p>
            <p className='Main-Dashboard-Card-SmallTitle'>{item.smallTitle}</p>
        </div>
    )
}

export default InsDashBoardCard