import React from 'react'
import { useOutletContext } from 'react-router-dom';
import './QuestionsDetails.scss';
import { GetWord } from '../../../../../utils';



const QuestionsDetails = () => {
    const [pageName, setPageName] = useOutletContext();

    setPageName(GetWord("Soru Detayı"));


    return (
        <div>

            <div className='QuestionsDetails-FirstArea'>
                <div className='QuestionsDetails-FirstArea-Style'>
                    <p className='QuestionsDetails-FirstArea-Text'>Mehmet ALi KARA</p>
                    <p className='QuestionsDetails-FirstArea-Text'>mehmetalikara42@gmail.com</p>
                    <p className='QuestionsDetails-FirstArea-Text'>Aktif Aboneliği: Aylık 100 Soru</p>
                    <p className='QuestionsDetails-FirstArea-Text'>Soruyu Cevaplayan Eğitmen: Ahmet</p>
                    <p className='QuestionsDetails-FirstArea-Text'>Puan: 5</p>
                    <p className='QuestionsDetails-FirstArea-Text'>Soru Hakkı: 84</p>
                    <p className='QuestionsDetails-FirstArea-Text'>Şık: D</p>
                    <p className='QuestionsDetails-FirstArea-Text'>Yorum: Hızlı Çözün</p>
                </div>
                <div className='QuestionsDetails-FirstArea-Annotation'>
                    <p className='QuestionsDetails-FirstArea-Annotation-AnnotationTitle'>Ek Açıklama:</p>
                    <p className='QuestionsDetails-FirstArea-Annotation-MessageTitle'>Öğrenci:</p>
                    <p className='QuestionsDetails-FirstArea-Annotation-Message'>Sorudan hirbir şey anlamadım</p>
                    <p className='QuestionsDetails-FirstArea-Annotation-MessageTitle'>Eğitmen:</p>
                    <p className='QuestionsDetails-FirstArea-Annotation-Message'>Soruda xleri çekip y yerine yazdık</p>

                </div>


            </div>
            <div className='QuestionsDetails-SecondArea'>
                <div>
                    <p className='QuestionsDetails-SecondArea-Text'>Soru:</p>
                    <img src='https://lh3.googleusercontent.com/q-Ag6tqk1BGvUHvq6HxPEEnBh_n9BxWoSp3k0gDNyyYDc_YCwJdOQq7Pcs35yjA0CVlv2koGkYWsw5iJBPuFQxv3I250TU-syesKmoAE0-Fzckvd7-PCORyTDUZUlQUeuH3RVFkn=s0'
                        className='QuestionsDetails-SecondArea-Question' alt='' />
                </div>
                <div>
                    <p className='QuestionsDetails-SecondArea-Text'>Cevap:</p>
                    <img src='https://lh3.googleusercontent.com/q-Ag6tqk1BGvUHvq6HxPEEnBh_n9BxWoSp3k0gDNyyYDc_YCwJdOQq7Pcs35yjA0CVlv2koGkYWsw5iJBPuFQxv3I250TU-syesKmoAE0-Fzckvd7-PCORyTDUZUlQUeuH3RVFkn=s0'
                        className='QuestionsDetails-SecondArea-Question' alt='' />
                </div>

            </div>


        </div>
    )
}

export default QuestionsDetails
