import React from 'react'
import './StudentsDetails.scss';
import { Form, Input, Select, Table } from 'antd';
import { Option } from 'antd/es/mentions';
import { Link, useOutletContext } from 'react-router-dom';
import { GetWord } from '../../../../../utils';


const StudentsDetails = () => {

    const [pageName, setPageName] = useOutletContext();
    setPageName(GetWord("Öğrenci Detay"));

    const ListColumns = [
        {
            title: 'Paket',
            dataIndex: 'package',

        },
        {
            title: 'Alım Tarihi',
            dataIndex: 'purchaseDate',
        },
        {
            title: 'Bitiş Tarihi',
            dataIndex: 'endDate',
        },

        {
            title: 'Kullanım',
            dataIndex: 'use',
        },
        {
            title: 'Ücret',
            dataIndex: 'pay',
        },

    ];
    const ListData = [
        {
            package: 'Aylık 100 Soru',
            purchaseDate: '15.11.2021',
            endDate: '15.12.2022',
            use: '24',
            pay: '65.99₺',
        },
        {
            package: 'Aylık 100 Soru',
            purchaseDate: '15.11.2021',
            endDate: '15.12.2022',
            use: '24',
            pay: '65.99₺',
        },
        {
            package: 'Aylık 100 Soru',
            purchaseDate: '15.11.2021',
            endDate: '15.12.2022',
            use: '24',
            pay: '65.99₺',
        },
        {
            package: 'Aylık 100 Soru',
            purchaseDate: '15.11.2021',
            endDate: '15.12.2022',
            use: '24',
            pay: '65.99₺',
        },
    ];


    return (
        <div className='StudentsDetails'>
            <div className='StudentsDetails-FirstArea'>
                <div className='StudentsDetails-FirstArea-StudentInformation'>
                    <p className='StudentsDetails-FirstArea-StudentInformation-Text'>Mehmet Ali KARA</p>
                    <p className='StudentsDetails-FirstArea-StudentInformation-Text'>Sınıf: 12</p>
                    <p className='StudentsDetails-FirstArea-StudentInformation-Text'>Sınav: YKS</p>
                    <p className='StudentsDetails-FirstArea-StudentInformation-Text'>Toplam Soru Hakkı: 100</p>
                    <p className='StudentsDetails-FirstArea-StudentInformation-Text'>Kayıt Tarihi: 25.04.2021</p>
                </div>

                <div className='StudentsDetails-FirstArea-ActivePackages'>
                    <div className='StudentsDetails-FirstArea-ActivePackages-Title'>Aktif Paketleri</div>
                    <div className='StudentsDetails-FirstArea-ActivePackages-Comment'>
                        <div>
                            <p className='StudentsDetails-FirstArea-ActivePackages-Comment-Left'>Paket:</p>
                            <p className='StudentsDetails-FirstArea-ActivePackages-Comment-Left'>Kalan Soru Hakkı:</p>
                            <p className='StudentsDetails-FirstArea-ActivePackages-Comment-Left'>Başlangıç Tarihi:</p>
                            <p className='StudentsDetails-FirstArea-ActivePackages-Comment-Left'>Bitiş Tarihi:</p>
                            <div>
                                <p className='StudentsDetails-FirstArea-ActivePackages-Comment-Left'>Otomatik Yenileme:</p>
                                <p className='StudentsDetails-FirstArea-ActivePackages-Comment-Left-Explanation'>Paket bitmesine 7 gün kaldı</p>
                            </div>
                        </div>

                        <div>
                            <p className='StudentsDetails-FirstArea-ActivePackages-Comment-Right'>Aylık 100 Soru</p>
                            <p className='StudentsDetails-FirstArea-ActivePackages-Comment-Right'>41</p>
                            <p className='StudentsDetails-FirstArea-ActivePackages-Comment-Right'>20.01.2021</p>
                            <p className='StudentsDetails-FirstArea-ActivePackages-Comment-Right'>19.02.2021</p>
                            <div>
                                <p className='StudentsDetails-FirstArea-ActivePackages-Comment-Right'>Açık</p>
                                <p className='StudentsDetails-FirstArea-ActivePackages-Comment-Left-Explanation'> </p>
                            </div>

                        </div>
                    </div>
                </div>

            </div>


            <div className='StudentsDetails-SecondArea'>
                <div >
                    <div className='StudentsDetails-SecondArea-ButtonArea'>
                        <Form.Item >
                            <Input className='StudentsDetails-SecondArea-ButtonArea-SelectInput CustomPlaceholder' placeholder="Soru Hakkı Girin">

                            </Input>
                        </Form.Item>
                        <Select defaultValue="Option1" value="Option1" className='StudentsDetails-SecondArea-ButtonArea-SelectInput CustomPlaceholder' >
                            <Option value="Option1">
                                Günlük</Option>
                        </Select>

                        <Link to="/">
                            <button className="StudentsDetails-SecondArea-ButtonArea-Add" style={{ cursor: 'pointer' }}>
                                <span className="StudentsDetails-SecondArea-ButtonArea-ButtonText">Ekle</span>
                            </button>
                        </Link>
                    </div>

                    <div className='StudentsDetails-SecondArea-ButtonArea'>
                        <Link to="/">
                            <button className="StudentsDetails-SecondArea-ButtonArea-Block" style={{ cursor: 'pointer' }}>
                                <span className="StudentsDetails-SecondArea-ButtonArea-ButtonText">Bloke Et</span>
                            </button>
                        </Link>
                        <Link to="/">
                            <button className="StudentsDetails-SecondArea-ButtonArea-Delete" style={{ cursor: 'pointer' }}>
                                <span className="StudentsDetails-SecondArea-ButtonArea-ButtonText">Sil</span>
                            </button>
                        </Link>
                    </div>


                    <div className='StudentsDetails-SecondArea-ButtonArea'>
                        <Link to="/">
                            <button className="StudentsDetails-SecondArea-ButtonArea-ReportedQuestions" style={{ cursor: 'pointer' }}>
                                <span className="StudentsDetails-SecondArea-ButtonArea-ButtonText">Raporlanan Soruları</span>
                            </button>
                        </Link>
                        <Link to="/">
                            <button className="StudentsDetails-SecondArea-ButtonArea-ReportedSolutions" style={{ cursor: 'pointer' }}>
                                <span className="StudentsDetails-SecondArea-ButtonArea-ButtonText">Raporladığı Çözümler</span>
                            </button>
                        </Link>
                    </div>


                    <div className='StudentsDetails-SecondArea-ButtonArea'>
                        <Select defaultValue="Option1" className='StudentsDetails-SecondArea-ButtonArea-SelectInput CustomPlaceholder' >
                            <Option value="Option1"  >
                                Aktif Paketi Seç</Option>
                        </Select>

                        <Link to="/">
                            <button className="StudentsDetails-SecondArea-ButtonArea-Delete" style={{ cursor: 'pointer' }}>
                                <span className="StudentsDetails-SecondArea-ButtonArea-ButtonText">Sil</span>
                            </button>
                        </Link>
                    </div>

                    <div className='StudentsDetails-SecondArea-ButtonArea'>
                        <Form.Item >
                            <Input className='StudentsDetails-SecondArea-ButtonArea-SelectInput CustomPlaceholder' placeholder="Soru Hakkı Girin">
                            </Input>
                        </Form.Item>
                        <Link to="/">
                            <button className="StudentsDetails-SecondArea-ButtonArea-Delete" style={{ cursor: 'pointer' }}>
                                <span className="StudentsDetails-SecondArea-ButtonArea-ButtonText">Sil</span>
                            </button>
                        </Link>
                    </div>

                </div>

                <div className='StudentsDetails-SecondArea-History' >
                    <Table
                        columns={ListColumns}
                        dataSource={ListData}
                        pagination={false}
                        scroll={{ x: 'max-content', y: 300 }}

                    />
                    <p className='TableTotal'>Toplam: 645₺</p>


                </div>
            </div>




        </div>
    )
}

export default StudentsDetails
