import React from 'react'
import { Form, Input, Select, Table } from 'antd'
import './TeachersDetails.scss'
import { Link, useOutletContext } from 'react-router-dom'
import { GetWord } from '../../../../../utils'
import { Option } from 'antd/es/mentions'
import { ImCross } from "react-icons/im";


const TeachersDetails = () => {

    const [pageName, setPageName] = useOutletContext();
    setPageName(GetWord("Eğitmen Detay"));

    const PaymentColumns = [
        {
            title: 'Tarih',
            dataIndex: 'date',

        },
        {
            title: 'İsim',
            dataIndex: 'name',

        },
        {
            title: 'Banka',
            dataIndex: 'bank',

        },
        {
            title: 'IBAN Numarası',
            dataIndex: 'iban',

        },
        {
            title: 'Mevcut Bakiye',
            dataIndex: 'currentBalance',

        },
        {
            title: 'Kalan Bakiye',
            dataIndex: 'remainingBalance',

        },
        {
            title: 'Tutar',
            dataIndex: 'amount',

        },
    ];


    const PaymentData = [
        {
            date: '19.05.2021',
            name: 'Mehmet Ali KARA',
            bank: 'Ziraat Bankası',
            iban: 'TR76000990134567800100001',
            currentBalance: '106',
            remainingBalance: '10',
            amount: '96.40₺',
        },

    ];
    return (
        <div className='TeachersDetails'>

            <div className='TeachersDetails-FirstArea'>
                <div className='TeachersDetails-FirstArea-PersonalInformation'>
                    <p>
                        <p className='TeachersDetails-FirstArea-PersonalInformation-Title'>Kişisel Bilgiler</p>
                        <p className='TeachersDetails-FirstArea-PersonalInformation-Text'>Mehmet Ali KARA</p>
                        <p className='TeachersDetails-FirstArea-PersonalInformation-Text'>mehmetalikara42@gmail.com</p>
                        <p className='TeachersDetails-FirstArea-PersonalInformation-Text'>05445252525</p>
                        <p className='TeachersDetails-FirstArea-PersonalInformation-Text'>Kayıt Tarihi: 12.12.2021</p>
                        <p className='TeachersDetails-FirstArea-PersonalInformation-Text'>Dersler: Matematik, Fizik, Kimya, İngilizce</p>
                    </p>
                </div>
                <div className='TeachersDetails-FirstArea-Balance'>
                    <p className='TeachersDetails-FirstArea-Balance-Title'>Bakiye Ekle</p>
                    <Form.Item>
                        <Input className='TeachersDetails-FirstArea-Balance-Input CustomPlaceholder' placeholder="Bakiye Giriniz...">
                        </Input>
                    </Form.Item>
                    <Link to="/">
                        <button className="TeachersDetails-FirstArea-Balance-Button-Add-Style" style={{ cursor: 'pointer' }}>
                            <span className="TeachersDetails-FirstArea-Balance-Button-Add-Text">Ekle</span>
                        </button>
                    </Link>
                    <Link to="/">
                        <button className="TeachersDetails-FirstArea-Balance-Button-Delete-Style" style={{ cursor: 'pointer' }}>
                            <span className="TeachersDetails-FirstArea-Balance-Button-Delete-Text">Sil</span>
                        </button>
                    </Link>
                </div>

                <div className='TeachersDetails-FirstArea-Report'>
                    <Link to="/">
                        <button className="TeachersDetails-FirstArea-Report-ReportedQuestions" style={{ cursor: 'pointer' }}>
                            <span className="TeachersDetails-FirstArea-Report-ReportedQuestions-Text">Raporladığı Sorular</span>
                        </button>
                    </Link>
                    <Link to="/">
                        <button className="TeachersDetails-FirstArea-Report-ReportedSolutions" style={{ cursor: 'pointer' }}>
                            <span className="TeachersDetails-FirstArea-Report-ReportedSolutions-Text">Raporlanan Çözümleri</span>
                        </button>
                    </Link>
                </div>

                <div className='TeachersDetails-FirstArea-BlockDelete'>
                    <Link to="/">
                        <button className="TeachersDetails-FirstArea-BlockDelete-Block" style={{ cursor: 'pointer' }}>
                            <ImCross className="TeachersDetails-FirstArea-BlockDelete-Block-Icon" />
                            <span className="TeachersDetails-FirstArea-BlockDelete-Block-Text"> Bloke Et</span>
                        </button>
                    </Link>
                    <Link to="/">
                        <button className="TeachersDetails-FirstArea-BlockDelete-Delete" style={{ cursor: 'pointer' }}>
                            <ImCross className="TeachersDetails-FirstArea-BlockDelete-Delete-Icon" />
                            <span className="TeachersDetails-FirstArea-BlockDelete-Delete-Text">Sil</span>
                        </button>
                    </Link>
                </div>
            </div>

            <div className='TeachersDetails-SecondArea'>
                <div className='TeachersDetails-SecondArea-Left'>
                    <div className='TeachersDetails-SecondArea-Left-ButtonArea'>
                        <Select defaultValue="Option1" className='TeachersDetails-SecondArea-Left-ButtonArea-Select CustomPlaceholder' >
                            <Option value="Option1"  >
                                Ders Seç</Option>
                        </Select>

                        <Link to="/">
                            <button className="TeachersDetails-SecondArea-Left-ButtonArea-Delete" style={{ cursor: 'pointer' }}>
                                <span className="TeachersDetails-SecondArea-Left-ButtonArea-ButtonText">Sil</span>
                            </button>
                        </Link>
                        <Select defaultValue="Option1" className='TeachersDetails-SecondArea-Left-ButtonArea-Select CustomPlaceholder' >
                            <Option value="Option1"  >
                                Ders Seç</Option>
                        </Select>

                        <Link to="/">
                            <button className="TeachersDetails-SecondArea-Left-ButtonArea-Add" style={{ cursor: 'pointer' }}>
                                <span className="TeachersDetails-SecondArea-Left-ButtonArea-ButtonText">Ekle</span>
                            </button>
                        </Link>
                    </div>

                    <div className='TeachersDetails-SecondArea-Left-Payments'>
                        <p className='TeachersDetails-SecondArea-Left-Payments-Title'>Ödemeler</p>
                        <Table
                            columns={PaymentColumns}
                            dataSource={PaymentData}
                            pagination={false}
                            scroll={{ y: 300 }}
                        />
                    </div>

                    <div className='TeachersDetails-SecondArea-Left-WithdrawalRequest'>
                        <span className='TeachersDetails-SecondArea-Left-WithdrawalRequest-Title' >Mevcut Para Çekme Talebi</span>
                        <span className='TeachersDetails-SecondArea-Left-WithdrawalRequest-Text' >Mehmet Ali KARA</span>
                        <span className='TeachersDetails-SecondArea-Left-WithdrawalRequest-Text' >Ziraat Bankasu</span>
                        <span className='TeachersDetails-SecondArea-Left-WithdrawalRequest-Text' >TR76000990134567800100001</span>
                        <span className='TeachersDetails-SecondArea-Left-WithdrawalRequest-Text' >88.08 ₺</span>
                        <span className='TeachersDetails-SecondArea-Left-WithdrawalRequest-Text' >28.12.2021</span>
                    </div>


                </div>

                <div className='TeachersDetails-SecondArea-Right-Stats'>
                    <div className='TeachersDetails-SecondArea-Right-Stats-TitleSelect'>
                        <span className='TeachersDetails-SecondArea-Right-Stats-TitleSelect-Title'>İstatistikler</span>
                        <div className='TeachersDetails-SecondArea-Right-Stats-TitleSelect-IconSelect'>
                            <div className='TeachersDetails-SecondArea-Right-Stats-TitleSelect-IconSelect-Icon'>
                                <svg viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-272h-448zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12zm-128-128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12zm-128-128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12zm336-332h-48v-48c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48h-128v-48c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48h-48c-26.5 0-48 21.5-48 48v48h448v-48c0-26.5-21.5-48-48-48z" />
                                </svg>
                            </div>
                            <Select defaultValue="Option1" className='TeachersDetails-SecondArea-Right-Stats-TitleSelect-Select ' >
                                <Option value="Option1"  >
                                    Son 1 ay</Option>
                            </Select>
                        </div>
                    </div>
                    <div className='TeachersDetails-SecondArea-Right-Stats-Area'>
                        <div className='TeachersDetails-SecondArea-Right-Stats-Area-Left'>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Left-Text'>Toplam Kazanç</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Left-Text'>Alınan Ödemeler</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Left-Text'>Mevcut Bakiye</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Left-Text'>Toplam Soru</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Left-Text'>Cevaplanan Soru</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Left-Text'>Reddilen Soru</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Left-Text'>Ortalama Cevap Süresi</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Left-Text'>Değerlendirilen Soru</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Left-Text'>Değerlendirne Bekleyen Soru</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Left-Text'>Değerlendirilmeyen Soru</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Left-Text'>Ortalama Puan</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Left-Text'>Mesaj Yazılan Soru</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Left-Text'>Zaman Aşımına Uğrayan Soru</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Left-Text'>Raporlanan Soru</p>
                        </div>

                        <div className='TeachersDetails-SecondArea-Right-Stats-Area-Right'>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Right-Text'>890,90₺</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Right-Text'>800₺</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Right-Text'>90₺</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Right-Text'>900</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Right-Text'>350</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Right-Text'>12</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Right-Text'>7dk</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Right-Text'>89</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Right-Text'>12</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Right-Text'>24</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Right-Text'>4,2</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Right-Text'>64</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Right-Text'>64</p>
                            <p className='TeachersDetails-SecondArea-Right-Stats-Area-Right-Text'>48</p>

                        </div>

                    </div>

                </div>


            </div>


        </div>
    )
}

export default TeachersDetails
