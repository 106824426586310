import React from 'react'
import { NavLink } from 'react-router-dom'


const SideBarItem = ({ item }) => {
    return (
        <NavLink onClick={() => window.scrollTo(0, 0)} className="InsSideBar-Nav-Link" to={item.url}>
            {item.icon}
            {item.name}

        </NavLink>
    )
}

export default SideBarItem